import { useSessionStore } from '@/providers/store';
import { PlanInfo } from '@/types/plan';
import ChartComponent from '@/views/Plans/ProductDetails/components/ChartComponent';
import { useEffect, useState } from 'react';
import PlanDetailsInfoSection from './components/PlanDetailsInfoSection';
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useEnrollStore } from '@/providers/enrollStore';

export const calculatePrice = (
  usage: number,
  plan: PlanInfo
): { totalBill: string; averageRate: string; usageCredits: string } | null => {
  const tduBaseCharge = plan.tdu?.["tdu-pricing"]?.["fixed-charge-dollars"] || 0;
  const planBaseCharge = plan["plan-pricing"]?.["base-fee-monthly"] || 0;
  const totalBaseCharge = tduBaseCharge + planBaseCharge;
  const tduVariableCharge = plan.tdu?.["tdu-pricing"]?.["dollars-per-kwh"] || 0;
  let usageCredits = 0;
  if (plan["usage-credit"]) {
    for (let credit of plan["usage-credit"]) {
      if (usage >= credit["lower"] && usage <= credit["upper"]) {
        usageCredits += credit["dollars-credit"];
      }
    }
  }
  let planVariableCharge = 0;
  if (plan["plan-pricing"]["kwh-rate"]) {
    planVariableCharge = plan["plan-pricing"]["kwh-rate"] / 100;
  } else {
    planVariableCharge =
      (plan["plan-pricing"]["kwh-500"] +
        plan["plan-pricing"]["kwh-1000"] +
        plan["plan-pricing"]["kwh-2000"]) /
      3;
  }
  const totalVariableCharge = tduVariableCharge + planVariableCharge;
  const totalBill =
    totalBaseCharge + totalVariableCharge * usage - usageCredits;
  const averageRate = (totalBill * 100) / usage;
  const returnValue = {
    totalBill: totalBill.toFixed(2),
    averageRate: averageRate.toFixed(1),
    usageCredits: usageCredits.toFixed(2),
  };
  return returnValue;
};

const ProductDialog = ({ onClose, open, plan }: { onClose: () => void; open: boolean, plan: PlanInfo | null }): JSX.Element => {
  const { enroll } = useEnrollStore();
  const [showDetails, setShowDetails] = useState(true);
  const [pricing, setPricing] = useState({
    totalBill: '0',
    averageRate: '0',
    usageCredits: '0',
  });
  const [currentPlanIndex, setCurrentPlanIndex] = useState(0);

  const estimateUse = () => {
    const estimatedUsage = Math.round(enroll.service_homeSize * 1.6);
    if (!plan) {
      return;
    }
    const pricing = calculatePrice(estimatedUsage, plan);
    if (pricing) {
      setPricing(pricing);
    }
  };

  useEffect(() => {
    estimateUse();
  }, [enroll.service_homeSize, plan]);

  const nextPlan = () => {
    setCurrentPlanIndex((prevIndex) => (prevIndex + 1) % similarPlans.length);
  };

  const prevPlan = () => {
    setCurrentPlanIndex(
      (prevIndex) => (prevIndex - 1 + similarPlans.length) % similarPlans.length
    );
  };

  let similarPlans:any[] = [];

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <div className="py-2 px-4 w-full">
          <div className="py-2 flex justify-end">
            <button
              className="text-gray-500 hover:text-gray-700"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div id="Plan Details" className="py-2">
            <div id="Plan Info" className="flex flex-col items-center">
              <img src={plan?.retailer?.logo} className="w-[150px] h-[150px] object-contain" alt="Retailer Logo" />
              <h2 className="text-2xl font-bold mt-4">
                {plan?.["info"]["plan-name"]}
              </h2>
              <h3 className="text-xl text-gray-600 mt-2">
                by <a href={ plan?.retailer?.links?.website || ''} target='_blank' className="text-blue-600 hover:underline">
                {plan?.retailer?.name}
              </a>
              </h3>
              <p className="mt-2">{plan?.retailer?.contact?.phone}</p>
            </div>
            <div>
              {plan && (
                <ChartComponent plan={plan} />
              )}
              {plan && (
                <div className="flex flex-row gap-2 mt-4">
                  <Button onClick={() => setShowDetails(!showDetails)}>
                    {showDetails ? 'Hide' : 'Show'} Plan & Pricing Information
                  </Button>
                  {Object.keys(plan.links)
                    .filter((key) => plan.links[key as keyof typeof plan.links])
                    .map((key) => (
                      <Button
                        key={key}
                        onClick={() => {
                          const retailerWebsite = plan.retailer?.links?.website;
                          if (retailerWebsite) {
                            const link = plan.links[key as keyof typeof plan.links]!;
                            window.open(link, '_blank', 'noopener,noreferrer');
                          }
                        }}
                      >
                        {key.includes('Link') ? key.replace('Link', ' Link') : key}
                      </Button>
                    ))}
                </div>
              )}
              <div className='mb-3'>
                {showDetails && plan && <PlanDetailsInfoSection planInfo={plan} usage={1000} />}
              </div>
            </div>
            <div className="p-1 mt-2">
              <div className="flex flex-row justify-around">
                {plan?.features?.tags && (
                  <div className='bg-blue-100 p-4 m-4 shadow-sm rounded-lg'>
                    <h4 className="text-lg font-semibold mb-2">
                      You'll enjoy:
                    </h4>
                    <ul className='list-disc pl-5 text-blue-800'>
                      {plan?.features?.tags.map((item, index) => (
                        <li key={index} className='mb-1 text-sm sm:text-base'>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProductDialog;
