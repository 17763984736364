import "@/utils/leaflet-icons";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import { useSessionStore } from "@/providers/store";
import { useEnrollStore } from "@/providers/enrollStore";
import { useEffect, useState } from "react";
import KML from "react-leaflet-kml";
import Api from "@/utils/api";
import { TDU } from "@/types/plan";
const defaultPosition: LatLngExpression = { lat: 32.7294, lng: -96.9964 }; // Grand Prairie center

interface FilteredKMLProps {
  kmlData: Document;
  allowedZips: string[];
  showAll: boolean;
  showOutlines: boolean;
  color: string;
}

const colors = {
  ONCOR: "#FF0000",
  CP: "#00FF00",
  AEPTN: "#4444FF",
  LPL: "#FF44FF",
  AEPTC: "#FFAA00",
  TNMP: "#00FFFF",
};

// Add this helper function at the top of the file
const hexToKMLColor = (hex: string, alpha: number = 0.3): string => {
  // Remove the # if present
  hex = hex.replace("#", "");

  // Convert hex to RGB
  const r = hex.substring(0, 2);
  const g = hex.substring(2, 4);
  const b = hex.substring(4, 6);

  // Convert alpha from 0-1 to hex
  const a = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, "0");

  // Return in KML format (aabbggrr)
  const kmlColor = `${a}${b}${g}${r}`;
  console.log("Converting color:", hex, "to KML color:", kmlColor);
  return kmlColor;
};

// Component to filter KML data
const FilteredKML = ({
  kmlData,
  allowedZips,
  showAll,
  showOutlines,
  color,
}: FilteredKMLProps) => {
  const [filteredKml, setFilteredKml] = useState<Document | null>(null);
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (!kmlData) return;

    const newDoc = (kmlData as XMLDocument).implementation.createDocument(
      "http://www.opengis.net/kml/2.2",
      "kml",
      null
    );

    const documentEl = newDoc.createElement("Document");
    newDoc.documentElement.appendChild(documentEl);

    // Add default style (black outline or no outline based on showOutlines)
    const defaultStyle = newDoc.createElement("Style");
    defaultStyle.setAttribute("id", "defaultStyle");
    defaultStyle.innerHTML = `
      <LineStyle>
        <color>ff000000</color>
        <width>${showOutlines ? "1" : "0"}</width>
      </LineStyle>
      <PolyStyle>
        <color>00ffffff</color>
        <fill>1</fill>
        <outline>${showOutlines ? "1" : "0"}</outline>
      </PolyStyle>
      <LabelStyle>
        <color>ff000000</color>
        <scale>0.8</scale>
      </LabelStyle>
    `;
    documentEl.appendChild(defaultStyle);

    // Add highlighted style (red fill, always showing outline)
    const highlightStyle = newDoc.createElement("Style");
    highlightStyle.setAttribute("id", "highlightStyle");
    highlightStyle.innerHTML = `
      <LineStyle>
        <color>${color}</color>
        <width>2</width>
      </LineStyle>
      <PolyStyle>
        <color>${color}</color>
        <fill>1</fill>
        <outline>1</outline>
      </PolyStyle>
      <LabelStyle>
        <color>ff000000</color>
        <scale>0.8</scale>
      </LabelStyle>
    `;
    documentEl.appendChild(highlightStyle);

    // Copy name and description
    const originalName = kmlData.querySelector("name");
    const originalDesc = kmlData.querySelector("description");
    if (originalName) documentEl.appendChild(originalName.cloneNode(true));
    if (originalDesc) documentEl.appendChild(originalDesc.cloneNode(true));

    // Group placemarks by style (highlighted vs non-highlighted)
    const highlightedPlacemarks: Element[] = [];
    const defaultPlacemarks: Element[] = [];

    // First pass: Sort placemarks into groups
    const placemarks = kmlData.getElementsByTagName("Placemark");
    Array.from(placemarks).forEach((placemark) => {
      const extendedData = placemark.querySelector("ExtendedData");
      const zipDataElement = extendedData?.querySelector(
        'Data[name="ZCTA5CE10"]'
      );
      const zipValue = zipDataElement?.querySelector("value")?.textContent;

      if (zipValue) {
        const newPlacemark = placemark.cloneNode(true) as Element;
        // Add ZIP code as name
        const nameEl = newDoc.createElement("name");
        nameEl.textContent = zipValue;
        newPlacemark.insertBefore(nameEl, newPlacemark.firstChild);

        if (allowedZips.includes(zipValue)) {
          highlightedPlacemarks.push(newPlacemark);
        }
      }
    });

    // Create MultiGeometry for each group
    if (defaultPlacemarks.length > 0) {
      const defaultMultiGeometry = newDoc.createElement("Placemark");
      const multiGeom = newDoc.createElement("MultiGeometry");
      defaultMultiGeometry.appendChild(multiGeom);

      defaultPlacemarks.forEach((placemark) => {
        const polygon = placemark.querySelector("Polygon");
        if (polygon) {
          multiGeom.appendChild(polygon.cloneNode(true));
        }
      });

      const styleUrl = newDoc.createElement("styleUrl");
      styleUrl.textContent = "#defaultStyle";
      defaultMultiGeometry.appendChild(styleUrl);
      documentEl.appendChild(defaultMultiGeometry);
    }

    // Add highlighted placemarks individually to preserve labels
    highlightedPlacemarks.forEach((placemark) => {
      const styleUrl = newDoc.createElement("styleUrl");
      styleUrl.textContent = "#highlightStyle";
      placemark.appendChild(styleUrl);
      documentEl.appendChild(placemark);
    });

    setFilteredKml(newDoc);
    setKey((prev) => prev + 1);
  }, [kmlData, allowedZips, showAll, showOutlines]);

  return filteredKml ? <KML key={key} kml={filteredKml} /> : null;
};

// Component to handle map view updates
const MapUpdater = ({
  center,
  zoom,
}: {
  center: LatLngExpression;
  zoom: number;
}) => {
  const map = useMap();

  useEffect(() => {
    map.setView(center, zoom);
    map.invalidateSize();
  }, [map, center, zoom]);

  return null;
};

export interface allowedZipsGroups {
  [key: string]: string[];
}

const allTdus = ["ONCOR", "CP", "AEPTN", "LPL", "AEPTC", "TNMP"];
export type TDUWithMap = {
  color: string;
  zips: string[];
  kml: JSX.Element | null;
  active: boolean;
};
export type TDUSwithMap = { [key: string]: TDUWithMap };

const PriceMap = () => {
  const { enroll } = useEnrollStore();
  const position = enroll.service_coords || defaultPosition;
  const map_zoom = useSessionStore((state) => state.map_zoom) || 10;
  const [kmlData, setKmlData] = useState<Document | null>(null);
  const [showOutlines, setShowOutlines] = useState(false);
  const [tdus, setTdus] = useState<TDUSwithMap>({});
  const [activeTdus, setActiveTdus] = useState<string[]>(allTdus); // Start with all active

  // Initial setup - only runs once
  useEffect(() => {
    const initialTdus: TDUSwithMap = {};
    allTdus.forEach((tdu) => {
      initialTdus[tdu] = {
        color: colors[tdu as keyof typeof colors],
        zips: [],
        kml: null,
        active: true,
      };
    });
    setTdus(initialTdus);
  }, []);

  // Load ZIP codes
  useEffect(() => {
    if (!Object.keys(tdus).length) return;

    const loadZips = async () => {
      for (const tdu of Object.keys(tdus)) {
        const zips = await getZipsByTDU(tdu);
        setTdus((prev) => ({
          ...prev,
          [tdu]: {
            ...prev[tdu],
            zips,
          },
        }));
      }
    };

    loadZips();
  }, [Object.keys(tdus).length]);

  const handleToggleTDU = (tdu: string) => {
    setTdus((prev) => ({
      ...prev,
      [tdu]: {
        ...prev[tdu],
        active: !prev[tdu].active,
      },
    }));
    
    setActiveTdus((prev) => 
      prev.includes(tdu) 
        ? prev.filter(t => t !== tdu)
        : [...prev, tdu]
    );
  };

  const TDUButtons: React.ReactNode = allTdus.map((tdu) => {
    const isActive = tdus?.[tdu]?.active;
    const color = colors[tdu as keyof typeof colors];

    return (
      <button
        key={tdu}
        onClick={() => handleToggleTDU(tdu)}
        className={`px-4 py-2 rounded-md shadow-md transition-all ${
          isActive
            ? "bg-blue-500 text-white hover:bg-blue-600"
            : "bg-gray-200 text-gray-600 hover:bg-gray-300"
        }`}
        style={{
          borderLeft: isActive ? `4px solid ${color}` : 'none',
        }}
      >
        {tdu}
      </button>
    );
  });

  const getZipsByTDU = async (tdu: string) => {
    const zips = await Api.getZipsByTDU(tdu);
    return zips;
  };

  // Load KML file only once
  useEffect(() => {
    fetch("/zips-tx.kml")
      .then((res) => res.text())
      .then((kmlText) => {
        console.log("KML loaded, length:", kmlText.length);
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlText, "text/xml");
        console.log(
          "KML parsed, placemarks:",
          kml.getElementsByTagName("Placemark").length
        );
        setKmlData(kml);
      })
      .catch((err) => console.error("Error loading KML:", err));
  }, []);

  return (
    <div className="h-[80vh] max-w-[80vw] relative flex flex-col items-center justify-center mx-auto">
      <div className="absolute top-4 right-4 z-10 flex flex-col gap-2 bg-white/80 p-4 rounded-lg backdrop-blur-sm">
        <div className="flex flex-wrap gap-2 mb-2">{TDUButtons}</div>
        <button
          onClick={() => setShowOutlines(!showOutlines)}
          className="w-full bg-white px-4 py-2 rounded-md shadow-md hover:bg-gray-100 border border-gray-200"
        >
          {showOutlines ? "Hide Outlines" : "Show Outlines"}
        </button>
      </div>
      <MapContainer
        center={position}
        zoom={map_zoom}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }}
        className="z-0"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>Current Center</Popup>
        </Marker>
        {kmlData && tdus && activeTdus.map((tdu) => (
          <FilteredKML
            key={`${tdu}-${showOutlines}`}
            kmlData={kmlData}
            allowedZips={tdus[tdu].zips}
            showAll={true}
            showOutlines={showOutlines}
            color={hexToKMLColor(tdus[tdu].color, 0.5)}
          />
        ))}
      </MapContainer>
    </div>
  );
};

export default PriceMap;
