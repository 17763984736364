import React from "react";
import {
  Award,
  ThumbsUp,
  Zap,
  Leaf,
  Moon,
  Sun,
  Trophy,
  ClockAlert,
  CircleDollarSign,
} from "lucide-react";
import { PlanInfo } from "@/types/plan";

interface PlanBadgesProps {
  plan: PlanInfo;
}

export const PlanBadges: React.FC<PlanBadgesProps> = ({ plan }) => {
  console.log("plan", plan);
  const badges = [];
  // Popular badge
  if (plan.valueScore + plan.flexibilityScore + plan.featuresScore >= 85) {
    badges.push({
      id: "popular",
      label: "Popular",
      icon: <Trophy className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color:
        "bg-orange-50 text-orange-700 border-orange-300 hover:bg-orange-100",
    });
  }
  // Our Recommendation badge
  if (plan.valueScore >= 45) {
    badges.push({
      id: "recommended",
      label: "Recommended",
      icon: <ThumbsUp className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color: "bg-blue-50 text-blue-800 border-blue-300 hover:bg-blue-100",
    });
  }
  // Green Energy badge
  if (plan.language?.toLowerCase() === "english") {
    badges.push({
      id: "spanish",
      label: "Spanish",
      icon: <ClockAlert className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color:
        "bg-indigo-50 text-indigo-700 border-indigo-200 hover:bg-indigo-100",
    });
  }
  if (plan["ai-analysis"]?.prepaid?.boolean) {
    badges.push({
      id: "green",
      label: "100% Green",
    });
  }

  if (plan["ai-analysis"]?.cancellationFee?.fee > 190) {
    badges.push({
      id: "cancellation-fee",
      label: `$${plan["ai-analysis"]?.cancellationFee?.fee} Cancellation Fee`,
      icon: (
        <CircleDollarSign className="w-4 h-4" style={{ strokeWidth: 1.5 }} />
      ),
      color: "bg-red-50 text-red-700 border-red-300 hover:bg-red-100",
    });
  }

  // Satisfaction Guarantee badge
  if (plan.features["satisfaction-guarantee"]) {
    badges.push({
      id: "guarantee",
      label: `Satisfaction Guarantee`,
      icon: <Award className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color: "bg-green-50 text-green-700 border-green-200 hover:bg-green-100",
    });
  }

  // Bill Credit badge
  if (plan["usage-credit"] && plan["usage-credit"]?.length > 0) {
    badges.push({
      id: "bill-credit",
      label: "Bill Credit",
      icon: <Zap className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color:
        "bg-purple-50 text-purple-700 border-purple-200 hover:bg-purple-100",
    });
  }

  // Green Energy badge
  if (plan.renewablePercentage >= 100) {
    badges.push({
      id: "green",
      label: "100% Green",
      icon: <Leaf className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color:
        "bg-emerald-50 text-emerald-700 border-emerald-200 hover:bg-emerald-100",
    });
  }

  // Green Energy badge
  if (plan["features"]["free-nights"]) {
    badges.push({
      id: "free-nights",
      label: "Free Nights",
      icon: <Moon className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color:
        "bg-indigo-50 text-indigo-700 border-indigo-200 hover:bg-indigo-100",
    });
  }

  // Green Energy badge
  if (plan.features["time-of-use"]) {
    badges.push({
      id: "time-of-use",
      label: "Time of Use",
      icon: <ClockAlert className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
      color:
        "bg-indigo-50 text-indigo-700 border-indigo-200 hover:bg-indigo-100",
    });
  }

  if (badges.length === 0) return null;

  return (
    <div className="flex flex-col items-start justify-start gap-1">
      {badges.slice(0, 4).map((badge) => (
        <div
          key={badge.id}
          className={`inline-flex items-center gap-1.5 m-0.5 px-2 py-0.5 rounded-full text-xs font-medium border transition-colors cursor-default ${badge.color}`}
          title={badge.label}
        >
          {badge.icon}
          <span>{badge.label}</span>
        </div>
      ))}
    </div>
  );
};


// import React from "react";
// import {
//   Award,
//   ThumbsUp,
//   Zap,
//   Leaf,
//   Moon,
//   Sun,
//   Trophy,
//   ClockAlert,
//   CircleDollarSign,
// } from "lucide-react";
// import { PlanInfo } from "@/types/plan";

// interface PlanBadgesProps {
//   plan: PlanInfo;
// }

// export const PlanBadges: React.FC<PlanBadgesProps> = ({ plan }) => {
//   console.log("plan", plan);
//   const badges = [];
//   // Popular badge
//   if (plan.valueScore + plan.flexibilityScore + plan.featuresScore >= 85) {
//     badges.push({
//       id: "popular",
//       label: "Popular",
//       icon: <Trophy className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
//       color:
//         // "bg-orange-50 text-orange-700 border-orange-300 hover:bg-orange-100",
//     });
//   }
//   // Our Recommendation badge
//   if (plan.valueScore >= 45) {
//     badges.push({
//       id: "recommended",
//       label: "Recommended",
//       icon: <ThumbsUp className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
//       color: "bg-blue-50 text-blue-800 border-blue-300 hover:bg-blue-100",
//     });
//   }
//   // Green Energy badge
//   if (plan.language?.toLowerCase() === "english") {
//     badges.push({
//       id: "spanish",
//       label: "Spanish",
//       icon: <ClockAlert className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
//       color:
//         "bg-indigo-50 text-indigo-700 border-indigo-200 hover:bg-indigo-100",
//     });
//   }
//   if (plan["ai-analysis"]?.prepaid?.boolean) {
//     badges.push({
//       id: "green",
//       label: "100% Green",
//     });
//   }

//   if (plan["ai-analysis"]?.cancellationFee?.fee > 190) {
//     badges.push({
//       id: "cancellation-fee",
//       label: `$${plan["ai-analysis"]?.cancellationFee?.fee} Cancellation Fee`,
//       icon: (
//         <CircleDollarSign className="w-4 h-4" style={{ strokeWidth: 1.5 }} />
//       ),
//       color: "bg-red-50 text-red-700 border-red-300 hover:bg-red-100",
//     });
//   }

//   // Satisfaction Guarantee badge
//   if (plan.features["satisfaction-guarantee"]) {
//     badges.push({
//       id: "guarantee",
//       label: `Satisfaction Guarantee`,
//       icon: <Award className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
//       color: "bg-green-50 text-green-700 border-green-200 hover:bg-green-100",
//     });
//   }

//   // Bill Credit badge
//   if (plan["usage-credit"] && plan["usage-credit"]?.length > 0) {
//     badges.push({
//       id: "bill-credit",
//       label: "Bill Credit",
//       icon: <Zap className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
//       color:
//         "bg-purple-50 text-purple-700 border-purple-200 hover:bg-purple-100",
//     });
//   }

//   // Green Energy badge
//   if (plan.renewablePercentage >= 100) {
//     badges.push({
//       id: "green",
//       label: "100% Green",
//       icon: <Leaf className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
//       color:
//         "bg-emerald-50 text-emerald-700 border-emerald-200 hover:bg-emerald-100",
//     });
//   }

//   // Green Energy badge
//   if (plan["features"]["free-nights"]) {
//     badges.push({
//       id: "free-nights",
//       label: "Free Nights",
//       icon: <Moon className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
//       color:
//         "bg-indigo-50 text-indigo-700 border-indigo-200 hover:bg-indigo-100",
//     });
//   }

//   // Green Energy badge
//   if (plan.features["time-of-use"]) {
//     badges.push({
//       id: "time-of-use",
//       label: "Time of Use",
//       icon: <ClockAlert className="w-3.5 h-3.5" style={{ strokeWidth: 1.5 }} />,
//       color:
//         "bg-indigo-50 text-indigo-700 border-indigo-200 hover:bg-indigo-100",
//     });
//   }

//   if (badges.length === 0) return null;

//   return (
//     <div className="flex flex-col items-start justify-start gap-1">
//       {badges.slice(0, 4).map((badge) => (
//         <div
//           key={badge.id}
//           className={`inline-flex items-center gap-1.5 m-0.5 px-2 py-0.5 rounded-full text-xs font-medium border transition-colors cursor-default ${badge.color}`}
//           title={badge.label}
//         >
//           {badge.icon}
//           <span>{badge.label}</span>
//         </div>
//       ))}
//     </div>
//   );
// };
