import React from 'react';
import { useTheme } from '@/theme/ThemeContext';
import { cn } from '@/lib/utils';

interface Props {
  children: React.ReactNode;
  className?: string;
  background?: string;
}

export default function Page({ children, className, background }: Props): JSX.Element {
  const { mode } = useTheme();

  return (
    <div className={cn("min-h-screen w-full", background)}>
    <div 
      className={cn(
        "min-h-screen max-w-[900px] lg:max-w-[1200px] mx-auto",
        "text-foreground",
        `app ${mode}`,
        className
      )}
    >
      {children}
    </div>
    </div>
  );
}
