import React, { useEffect, useRef } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { config } from "@/config";
import { Library } from "@googlemaps/js-api-loader";
import { useEnrollStore } from "@/providers/enrollStore";
import { useSessionStore } from "@/providers/store";
import { LatLngLiteral } from "leaflet";
import "./addressAutocomplete.css";
import Api from "@/utils/api";

interface AddressAutocompleteProps {
  onSelectAddress: (address: string) => void;
  placeholder?: string;
}

const libraries = ["places"];

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  onSelectAddress,
  placeholder,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setLocationSpec } = useSessionStore();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
    libraries: libraries as Library[],
  });

  const {
    setEnroll_service_address,
    setEnroll_service_coords,
    setEnroll_service_address_city,
    setEnroll_service_address_state,
    setEnroll_service_address_zip,
    setEnroll_service_address_line1,
    setEnroll_service_address_esiId,
    setEnroll_service_address_place,
  } = useEnrollStore();

  useEffect(() => {
    if (!isLoaded || !inputRef.current) return;

    const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
      fields: ["formatted_address", "geometry", "address_components"],
      componentRestrictions: { country: "us" },
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.formatted_address && place.geometry) {
        console.log("PLACE", place);
        const { formatted_address, geometry, address_components } = place;

        const address = formatted_address;
        const coords = {
          lat: geometry.location?.lat(),
          lng: geometry.location?.lng(),
        };

        let streetNumber = "";
        let streetName = "";
        let city = "";
        let state = "";
        let zip = "";

        address_components?.forEach((component) => {
          const types = component.types;
          if (types.includes("locality")) {
            city = component.long_name;
          }
          if (types.includes("administrative_area_level_1")) {
            state = component.short_name;
          }
          if (types.includes("postal_code")) {
            zip = component.long_name;
          }
          if (types.includes("street_number")) {
            streetNumber = component.long_name;
          }
          if (types.includes("route")) {
            streetName = component.long_name;
          }
        });

        setEnroll_service_address_place(place);
        setEnroll_service_coords(coords as LatLngLiteral);
        setEnroll_service_address_city(city);
        setEnroll_service_address_state(state);
        setEnroll_service_address_zip(zip);
        if (streetNumber && streetName) {
          setEnroll_service_address_line1(`${streetNumber} ${streetName}`);
          setEnroll_service_address(address);
          setLocationSpec("address");
          Api.findEsiId({
            rawAddress: address,
            addressLine: streetNumber + " " + streetName,
            city: city,
            state: state,
            zip: zip,
            coords: coords as LatLngLiteral,
          }).then((esiId) => {
            setEnroll_service_address_esiId(esiId);
          });
        } else {
          zip && setLocationSpec("zip");
        }

        onSelectAddress(zip);
      }
    });
  }, [
    isLoaded,
    onSelectAddress,
    setEnroll_service_address,
    setEnroll_service_coords,
    setEnroll_service_address_city,
    setEnroll_service_address_state,
    setEnroll_service_address_zip,
    setEnroll_service_address_place,
  ]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      onSelectAddress("");
    }
  };

  return (
    <input
      ref={inputRef}
      type="text"
      placeholder={placeholder || "Enter your address"}
      className="grow w-full outline-none bg-inherit mx-6 rounded-lg"
      onChange={handleInputChange}
    />
  );
};

export default AddressAutocomplete;
