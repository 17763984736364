import React from "react";
import { UsageTiers, usePlansStore } from "@/providers/planStore";
import { useSessionStore } from "@/providers/store";
import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { ScrollArea } from "@/components/ui/scroll-area";
import { X } from "lucide-react";
import { useState } from "react";
import { cn } from "@/lib/utils";
const EmptyCompareCard = () => {
  return (
    <div className="border border-dashed border-gray-300 rounded-lg relative p-4 bg-gray-100 mt-8 flex flex-col items-center justify-center">
      <p className="text-sm text-muted-foreground">Add Plan</p>
    </div>
  );
};

export const CompareDrawer = () => {
  const { comparePlans, plans, PTCPlans, removeComparePlan, filters, setUsageTier } = usePlansStore();
  const [isComparing, setIsComparing] = useState(false);
  const allPlans = [...plans, ...PTCPlans];
  const plansToCompare = allPlans.filter((plan) =>
    comparePlans.includes(plan.getxid)
  );

  const emptyCardCount = 5 - plansToCompare.length;
  const emptyCards = Array(emptyCardCount).fill(null);

  const ComparisonTable = () => (
    <div className="w-full px-6 my-8">
      <div className="grid grid-cols-[200px_repeat(5,1fr)] border-collapse border-r">
        {/* Headers Row */}
        <div className="flex flex-col items-center justify-center border-b border-l border-t">
          <div className="p-4 font-semibold">Avg Monthly Usage</div>
          <Button variant="outline" size="icon" className={cn("w-[50%]", filters.usageTier.value === UsageTiers.LOW ? "bg-mainBlue text-white" : "")} onClick={() => setUsageTier({enabled: true, value: UsageTiers.LOW})}>
            500 kWh
          </Button>
          <Button variant="outline" size="icon" className={cn("w-[50%]", filters.usageTier.value === UsageTiers.MEDIUM ? "bg-mainBlue text-white" : "")} onClick={() => setUsageTier({enabled: true, value: UsageTiers.MEDIUM})}>
            1000 kWh
          </Button>
          <Button variant="outline" size="icon" className={cn("w-[50%]", filters.usageTier.value === UsageTiers.HIGH ? "bg-mainBlue text-white" : "")} onClick={() => setUsageTier({enabled: true, value: UsageTiers.HIGH})}>
            2000 kWh
          </Button>
        </div>
        {plansToCompare.map((plan) => (
          <div
            key={plan.getxid}
            className="border-b border-l border-t last:border-r p-4 relative"
          >
            <Button
              variant="outline"
              size="icon"
              className="absolute top-2 right-2 h-6 w-6 rounded-full hover:bg-destructive hover:text-destructive-foreground shadow-sm"
              onClick={() => removeComparePlan(plan.getxid)}
            >
              <X className="h-8 w-8" />
            </Button>
            <div className="space-y-2 h-full flex flex-col items-center justify-between text-center">
              <img
                src={plan.data.retailer.logo}
                alt={plan.data.retailer.name}
                className="h-8 object-contain"
              />
              <div className="font-semibold w-[60%]">{plan.data.info["plan-name"]}</div>
              <div className="text-3xl font-bold text-mainBlue flex flex-col items-center justify-center">
                {plan.data["plan-pricing"][`kwh-${filters.usageTier.value}`].toFixed(1)}¢
                <div className="flex flex-col items-center justify-center">
                  <span className="text-sm text-muted-foreground !p-0"> per kWh</span>
                </div>
              </div>
              
            </div>
          </div>
        ))}
        {emptyCards.map((_, index) => (
          <div key={`empty-${index}`} className="border-b border-l p-4 last:border-r">
            <EmptyCompareCard />
          </div>
        ))}

        {/* Other Features */}
        {[
          { label: "Locked Rate", key: "locked-rate" },
          { label: "Contract Length", key: "term-length" },
          { label: "Rate at 1000 kWh", key: `kwh-${filters.usageTier.value}` },
          { label: "Monthly Bill", key: "monthly-bill" },
          { label: "Guarantee", key: "satisfaction-guarantee" },
          { label: "Termination Fee", key: "early-termination-fee" },
          { label: "Bill Credits", key: "usage-credit" },
          { label: "Green Energy", key: "pct-renewable" },
        ].map(({ label, key }) => (
          <React.Fragment key={key}>
            <div className="border-b p-4 font-semibold border-l flex flex-col items-center justify-center border-r">{label}</div>
            {[...Array(5)].map((_, i) => (
              <div
                key={`${key}-${i}`}
                className="border-b border-l p-4"
              >
                {i < plansToCompare.length ? (
                  <div className="flex flex-col items-center justify-center">
                    {key === "term-length" &&
                      `${plansToCompare[i].data[key]} Months`}
                    {key.startsWith("kwh") &&
                      `${plansToCompare[i].data["plan-pricing"][`kwh-${filters.usageTier.value}`].toFixed(
                        1
                      )}¢`}
                    {key === "monthly-bill" &&
                      `$${(
                        plansToCompare[i].data["plan-pricing"][`kwh-${filters.usageTier.value}`] * 10
                      ).toFixed(2)}`}
                    {key === "early-termination-fee" &&
                      `$${plansToCompare[i].data.features[key] || "0"}`}
                    {key === "satisfaction-guarantee" &&
                      (plansToCompare[i].data.features[key] ? "Yes" : "No")}
                    {key === "usage-credit" &&
                      (plansToCompare[i].data[key] ? "Yes" : "No")}
                    {key === "pct-renewable" &&
                      (plansToCompare[i].data.features[key] ? "100%" : "0%")}
                    {key === "locked-rate" && "Yes"}
                  </div>
                ) : null}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );

  if (comparePlans.length === 0) return null;

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button variant="outline" className="gap-2">
          Compare Plans ({comparePlans.length})
        </Button>
      </DrawerTrigger>

      <DrawerContent
        className={cn(
          "min-h-[420px]",
          isComparing ? "max-h-[80vh]" : "max-h-[16vh]"
        )}
      >
        <div className="mx-auto w-full max-w-[80vw]">
          <DrawerHeader className="border-b px-6 py-4">
            <div className="w-full flex flex-col items-left justify-center">
              <DrawerTitle className="text-xl font-bold">
                Compare Plans
              </DrawerTitle>
              <DrawerDescription className="text-sm text-muted-foreground">
                Compare your selected plans side by side
              </DrawerDescription>
            </div>
          </DrawerHeader>

          <ScrollArea className="max-w-[80vw]">
            {isComparing ? (
              <ComparisonTable />
            ) : (
              <div className="grid grid-cols-5 gap-6">
                {plansToCompare.map((plan) => (
                  <div
                    key={plan.getxid}
                    className="border rounded-lg relative py-1 px-4 bg-white mt-8"
                  >
                    <div className="flex flex-col items-left justify-between">
                      <img
                        src={plan.data.retailer.logo}
                        alt={plan.data.retailer.name}
                        className="w-16 h-16"
                      />
                      <h3 className="font-semibold text-md">
                        {plan.data.info["plan-name"]}
                      </h3>
                      <Button
                        variant="outline"
                        size="icon"
                        className="absolute -top-2 -right-2 h-6 w-6 rounded-full hover:bg-destructive hover:text-destructive-foreground rounded-full"
                        onClick={() => removeComparePlan(plan.getxid)}
                      >
                        <X className="h-8 w-8" />
                      </Button>
                    </div>

                    <div className="">
                      {/* <div className="flex flex-col items-left">
                        <p className="text-sm text-muted-foreground">
                          {plan.data.retailer.name}
                        </p>
                        <p className="text-sm text-muted-foreground">
                          {plan.data["term-length"]} months
                        </p>
                      </div> */}

                      <div className="py-2">
                        <p className="text-2xl font-bold text-mainBlue">
                          {plan.data["plan-pricing"][`kwh-${filters.usageTier.value}`].toFixed(1)}¢
                        </p>
                        <p className="text-sm text-muted-foreground">per kWh</p>
                      </div>
                    </div>
                  </div>
                ))}

                {emptyCards.map((_, index) => (
                  <EmptyCompareCard key={`empty-${index}`} />
                ))}
              </div>
            )}
          </ScrollArea>

          <DrawerFooter className="w-full flex flex-col items-center justify-center">
            <Button
              className="w-full"
              disabled={comparePlans.length < 3}
              onClick={() => setIsComparing(!isComparing)}
            >
              {isComparing ? "Back to Cards" : "Compare"}
            </Button>
            {comparePlans.length < 3 && (
              <p className="text-sm text-red-500">
                You need to select at least 3 plans to compare
              </p>
            )}
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
