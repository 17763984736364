import SearchBar2 from "@/components/individual/newSearch";
import { SortBy, sortByOptions, usePlansStore } from "@/providers/planStore";
import { useSessionStore } from "@/providers/store";
import UsageTier from "../UsageTier/UsageTier";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useEnrollStore } from "@/providers/enrollStore";

interface Props {
  onSidebarOpen: () => void;
  zipCode?: string;
}

const Topbar = (): JSX.Element => {
  const { filters, filteredPlans, setSortBy } = usePlansStore();
  const { enroll } = useEnrollStore();

  const handleSelectChange = (value: string) => {
    setSortBy(value as SortBy);
  };

  return (
    <div className="flex flex-col w-full bg-background p-4 rounded-lg border-2 shadow-lg">
      <div id="topbar" className="flex flex-col sm:flex-row sm:items-center justify-between w-full gap-4">
        <div className="w-full sm:w-[80%] mr-4">
          <SearchBar2 />
        </div>
        <div id="sort-by" className="flex flex-col sm:flex-row items-center justify-start sm:justify-end w-full sm:w-auto">
          <span className="text-primary mr-2 cursor-default">Sort by</span>
          <Select onValueChange={handleSelectChange} value={filters.sort.value}>
            <SelectTrigger className="w-[200px] cursor-default">
              <SelectValue placeholder="Select sort option" />
            </SelectTrigger>
            <SelectContent className="cursor-default">
              {sortByOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:items-center justify-between w-full mt-4 cursor-default">
        <p>
          Displaying {filteredPlans.length} plans for{" "}
          {enroll.service_address_zip}
        </p>
        <UsageTier />
      </div>
    </div>
  );
};

export default Topbar;
