import { create } from "zustand";

import { encrypt } from "@/helpers/encoding";
import { UsageTierOpts } from "@/types/frontend";
import { LatLngExpression } from "leaflet";
import { v4 as uuidv4 } from "uuid";
import { usePlansStore } from "./planStore";
import { useSessionStore } from "./store";
import { config } from "@/config";
export enum EnrollStep {
  ENTER_LOCATION = "ENTER_LOCATION",
  PLAN_SELECTION = "PLAN_SELECTION",
  SERVICE_DETAILS = "SERVICE_DETAILS",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  REVIEW_ORDER = "REVIEW_ORDER",
  CREDIT_CHECK = "CREDIT_CHECK",
  CREDIT_RETURNED = "CREDIT_RETURNED",
  CREDIT_APPROVED = "CREDIT_APPROVED",
  CONFIRMATION = "CONFIRMATION",
}

export type EnrollData = {
  enrollId: string;
  step: EnrollStep | null;
  creditInfo: any;
  creditScore_provider_accountId?: string;
  creditScore_provider_id?: string;
  creditScore_provider?: string;
  creditScore?: number;
  dob?: string;
  email_verified_at?: number;
  email_verified: boolean;
  email: string;
  enrollEndTime: number;
  enrollStartTime: number;
  error: boolean;
  errorMessage: string;
  firstName: string;
  lastName: string;
  paymentMethod_billing_address_city: string;
  paymentMethod_billing_address_country: string;
  paymentMethod_billing_address_email: string;
  paymentMethod_billing_address_line1: string;
  paymentMethod_billing_address_line2: string;
  paymentMethod_billing_address_name: string;
  paymentMethod_billing_address_phone: string;
  paymentMethod_billing_address_sameAsEnrollAddress: boolean;
  paymentMethod_billing_address_state: string;
  paymentMethod_billing_address_verified_at?: number;
  paymentMethod_billing_address_verified: boolean;
  paymentMethod_billing_address_zip: string;
  paymentMethod_cardType: string;
  paymentMethod_cardNumber: string;
  paymentMethod_cardName: string;
  paymentMethod_expMonth: number | null;
  paymentMethod_expYear: number | null;
  paymentMethod_last4: string;
  paymentMethod: string;
  paymentMethod_verified_at?: number;
  paymentMethod_verified: boolean;
  phone_verified_at?: number;
  phone_verified: boolean;
  phone: string;
  planId: string;
  retailerId: string;
  service_address_place: google.maps.places.PlaceResult | null;
  service_address_esiId: string;
  service_address_city: string;
  service_address_line1: string;
  service_address_line2: string;
  service_address_state: string;
  service_address_zip: string;
  service_address: string;
  service_coords: LatLngExpression;
  service_esiId: string;
  service_estimatedUsage: number;
  service_homeSize: number;
  service_meterNumber: string;
  service_place?: google.maps.places.PlaceResult;
  service_startDate: Date;
  service_startDate_earliestPossible: boolean;
  service_status: string;
  service_type: string;
  service_changeType: string;
  sessionId: string;
  ssn?: string;
  tdu: string;
  tduId: string;
  usageTier: UsageTierOpts;
  userId: string;
};

export interface EnrollState {
  enroll: EnrollData;
  startEnroll: (
    planId: string,
    retailerId: string,
    zipCode: string,
    sessionId: string
  ) => void;
  setEnroll_step: (enroll_step: EnrollState["enroll"]["step"]) => void;
  setEnroll_creditInfo: (
    enroll_creditInfo: EnrollState["enroll"]["creditInfo"]
  ) => void;
  setEnroll_creditScore_provider_accountId: (
    enroll_creditScore_provider_accountId: EnrollState["enroll"]["creditScore_provider_accountId"]
  ) => void;
  setEnroll_creditScore_provider_id: (
    enroll_creditScore_provider_id: EnrollState["enroll"]["creditScore_provider_id"]
  ) => void;
  setEnroll_creditScore_provider: (
    enroll_creditScore_provider: EnrollState["enroll"]["creditScore_provider"]
  ) => void;
  setEnroll_creditScore: (
    enroll_creditScore: EnrollState["enroll"]["creditScore"]
  ) => void;
  setEnroll_dob: (enroll_dob: EnrollState["enroll"]["dob"]) => void;
  setEnroll_email_verified_at: (
    enroll_email_verified_at: EnrollState["enroll"]["email_verified_at"]
  ) => void;
  setEnroll_email_verified: (
    enroll_email_verified: EnrollState["enroll"]["email_verified"]
  ) => void;
  setEnroll_email: (enroll_email: EnrollState["enroll"]["email"]) => void;
  setEnroll_error: (enroll_error: EnrollState["enroll"]["error"]) => void;
  setEnroll_errorMessage: (
    enroll_errorMessage: EnrollState["enroll"]["errorMessage"]
  ) => void;
  setEnroll_firstName: (
    enroll_firstName: EnrollState["enroll"]["firstName"]
  ) => void;
  setEnroll_enrollId: (
    enroll_enrollId: EnrollState["enroll"]["enrollId"]
  ) => void;
  setEnroll_lastName: (
    enroll_lastName: EnrollState["enroll"]["lastName"]
  ) => void;
  setEnroll_paymentMethod_billing_address_city: (
    enroll_paymentMethod_billing_address_city: EnrollState["enroll"]["paymentMethod_billing_address_city"]
  ) => void;
  setEnroll_paymentMethod_billing_address_country: (
    enroll_paymentMethod_billing_address_country: EnrollState["enroll"]["paymentMethod_billing_address_country"]
  ) => void;
  setEnroll_paymentMethod_billing_address_email: (
    enroll_paymentMethod_billing_address_email: EnrollState["enroll"]["paymentMethod_billing_address_email"]
  ) => void;
  setEnroll_paymentMethod_billing_address_line1: (
    enroll_paymentMethod_billing_address_line1: EnrollState["enroll"]["paymentMethod_billing_address_line1"]
  ) => void;
  setEnroll_paymentMethod_billing_address_line2: (
    enroll_paymentMethod_billing_address_line2: EnrollState["enroll"]["paymentMethod_billing_address_line2"]
  ) => void;
  setEnroll_paymentMethod_billing_address_name: (
    enroll_paymentMethod_billing_address_name: EnrollState["enroll"]["paymentMethod_billing_address_name"]
  ) => void;
  setEnroll_paymentMethod_billing_address_phone: (
    enroll_paymentMethod_billing_address_phone: EnrollState["enroll"]["paymentMethod_billing_address_phone"]
  ) => void;
  setEnroll_paymentMethod_billing_address_sameAsEnrollAddress: (
    enroll_paymentMethod_billing_address_sameAsEnrollAddress: EnrollState["enroll"]["paymentMethod_billing_address_sameAsEnrollAddress"]
  ) => void;
  setEnroll_paymentMethod_billing_address_state: (
    enroll_paymentMethod_billing_address_state: EnrollState["enroll"]["paymentMethod_billing_address_state"]
  ) => void;
  setEnroll_paymentMethod_billing_address_verified_at: (
    enroll_paymentMethod_billing_address_verified_at: EnrollState["enroll"]["paymentMethod_billing_address_verified_at"]
  ) => void;
  setEnroll_paymentMethod_billing_address_verified: (
    enroll_paymentMethod_billing_address_verified: EnrollState["enroll"]["paymentMethod_billing_address_verified"]
  ) => void;
  setEnroll_paymentMethod_billing_address_zip: (
    enroll_paymentMethod_billing_address_zip: EnrollState["enroll"]["paymentMethod_billing_address_zip"]
  ) => void;
  setEnroll_paymentMethod_cardType: (
    enroll_paymentMethod_cardType: EnrollState["enroll"]["paymentMethod_cardType"]
  ) => void;
  setEnroll_paymentMethod_cardNumber: (
    enroll_paymentMethod_cardNumber: EnrollState["enroll"]["paymentMethod_cardNumber"]
  ) => void;
  setEnroll_paymentMethod_cardName: (
    enroll_paymentMethod_cardName: EnrollState["enroll"]["paymentMethod_cardName"]
  ) => void;
  setEnroll_paymentMethod_expMonth: (
    enroll_paymentMethod_expMonth: EnrollState["enroll"]["paymentMethod_expMonth"]
  ) => void;
  setEnroll_paymentMethod_expYear: (
    enroll_paymentMethod_expYear: EnrollState["enroll"]["paymentMethod_expYear"]
  ) => void;
  setEnroll_paymentMethod_last4: (
    enroll_paymentMethod_last4: EnrollState["enroll"]["paymentMethod_last4"]
  ) => void;
  setEnroll_paymentMethod: (
    enroll_paymentMethod: EnrollState["enroll"]["paymentMethod"]
  ) => void;
  setEnroll_paymentMethod_verified_at: (
    enroll_paymentMethod_verified_at: EnrollState["enroll"]["paymentMethod_verified_at"]
  ) => void;
  setEnroll_paymentMethod_verified: (
    enroll_paymentMethod_verified: EnrollState["enroll"]["paymentMethod_verified"]
  ) => void;
  setEnroll_phone_verified_at: (
    enroll_phone_verified_at: EnrollState["enroll"]["phone_verified_at"]
  ) => void;
  setEnroll_phone_verified: (
    enroll_phone_verified: EnrollState["enroll"]["phone_verified"]
  ) => void;
  setEnroll_phone: (enroll_phone: EnrollState["enroll"]["phone"]) => void;
  setEnroll_service_address_place: (
    enroll_service_address_place: EnrollState["enroll"]["service_address_place"]
  ) => void;
  setEnroll_service_address_esiId: (
    enroll_service_address_esiId: EnrollState["enroll"]["service_address_esiId"]
  ) => void;
  setEnroll_service_address_city: (
    enroll_service_address_city: EnrollState["enroll"]["service_address_city"]
  ) => void;
  setEnroll_service_address_line1: (
    enroll_service_address_line1: EnrollState["enroll"]["service_address_line1"]
  ) => void;
  setEnroll_service_address_line2: (
    enroll_service_address_line2: EnrollState["enroll"]["service_address_line2"]
  ) => void;
  setEnroll_service_address_state: (
    enroll_service_address_state: EnrollState["enroll"]["service_address_state"]
  ) => void;
  setEnroll_service_address_zip: (
    enroll_service_address_zip: EnrollState["enroll"]["service_address_zip"]
  ) => void;
  setEnroll_service_address: (
    enroll_service_address: EnrollState["enroll"]["service_address"]
  ) => void;
  setEnroll_service_coords: (
    enroll_service_coords: EnrollState["enroll"]["service_coords"]
  ) => void;
  setEnroll_service_esiId: (
    enroll_service_esiId: EnrollState["enroll"]["service_esiId"]
  ) => void;
  setEnroll_service_estimatedUsage: (
    enroll_service_estimatedUsage: EnrollState["enroll"]["service_estimatedUsage"]
  ) => void;
  setEnroll_service_homeSize: (
    enroll_service_homeSize: EnrollState["enroll"]["service_homeSize"]
  ) => void;
  setEnroll_service_meterNumber: (
    enroll_service_meterNumber: EnrollState["enroll"]["service_meterNumber"]
  ) => void;
  setEnroll_service_place: (
    enroll_service_place: EnrollState["enroll"]["service_place"]
  ) => void;
  setEnroll_service_startDate: (
    enroll_service_startDate: EnrollState["enroll"]["service_startDate"]
  ) => void;
  setEnroll_service_startDate_earliestPossible: (
    enroll_service_startDate_earliestPossible: EnrollState["enroll"]["service_startDate_earliestPossible"]
  ) => void;
  setEnroll_service_status: (
    enroll_service_status: EnrollState["enroll"]["service_status"]
  ) => void;
  setEnroll_service_type: (
    enroll_service_type: EnrollState["enroll"]["service_type"]
  ) => void;
  setEnroll_service_changeType: (
    enroll_service_changeType: EnrollState["enroll"]["service_changeType"]
  ) => void;
  setEnroll_ssn: (enroll_ssn: EnrollState["enroll"]["ssn"]) => void;
  setEnroll_usageTier: (
    enroll_usageTier: EnrollState["enroll"]["usageTier"]
  ) => void;
  setEnroll_userId: (enroll_userId: EnrollState["enroll"]["userId"]) => void;
  setEnroll_planId: (enroll_planId: EnrollState["enroll"]["planId"]) => void;
  setEnroll_retailerId: (
    enroll_retailerId: EnrollState["enroll"]["retailerId"]
  ) => void;
  setEnroll_tduId: (enroll_tduId: EnrollState["enroll"]["tduId"]) => void;
  setEnroll_tdu: (enroll_tdu: EnrollState["enroll"]["tdu"]) => void;
  setEnroll_sessionId: (
    enroll_sessionId: EnrollState["enroll"]["sessionId"]
  ) => void;
  setEnroll: (enroll: EnrollState["enroll"]) => void;
}

export const useEnrollStore = create<EnrollState>((set) => ({
  enroll: {
    enrollId: "",
    step: EnrollStep.ENTER_LOCATION,
    creditInfo: null,
    email_verified: false,
    email: "",
    enrollEndTime: 0,
    enrollStartTime: 0,
    error: false,
    errorMessage: "",
    firstName: "",
    lastName: "",
    paymentMethod_billing_address_city: "",
    paymentMethod_billing_address_country: "",
    paymentMethod_billing_address_email: "",
    paymentMethod_billing_address_line1: "",
    paymentMethod_billing_address_line2: "",
    paymentMethod_billing_address_name: "",
    paymentMethod_billing_address_phone: "",
    paymentMethod_billing_address_sameAsEnrollAddress: true,
    paymentMethod_billing_address_state: "",
    paymentMethod_billing_address_verified: false,
    paymentMethod_billing_address_zip: "",
    paymentMethod_cardType: "",
    paymentMethod_cardNumber: "",
    paymentMethod_cardName: "",
    paymentMethod_expMonth: null,
    paymentMethod_expYear: null,
    paymentMethod_last4: "",
    paymentMethod: "",
    paymentMethod_verified: false,
    paymentMethod_verified_at: 0,
    phone_verified: false,
    phone: "",
    planId: "",
    retailerId: "",
    service_address_place: null,
    service_address_esiId: "",
    service_address_city:"",
    service_address_line1:"",
    service_address_line2:"",
    service_address_state:"",
    service_address_zip:"",
    service_address:"",
    service_coords: config.DEFAULT_POSITION,
    service_esiId: "",
    service_estimatedUsage: 0,
    service_homeSize: 0,
    service_meterNumber: "",
    service_startDate: new Date(),
    service_startDate_earliestPossible: false,
    service_status: "",
    service_type: "",
    service_changeType: "Switch",
    sessionId: "",
    ssn: "",
    dob: "",
    tdu: "",
    tduId: "",
    usageTier: UsageTierOpts.MEDIUM,
    userId: useSessionStore.getState().userId || "",
  },
  startEnroll: (
    planId: string,
    retailerId: string,
    zipCode: string,
    sessionId: string
  ) => {
    const enrollId = uuidv4();
    const tdu = usePlansStore
      .getState()
      .plans.find((plan) => plan.getxid === planId)?.tdu;
    const tduId = usePlansStore
      .getState()
      .plans.find((plan) => plan.getxid === planId)?.data.tdu.getxid;
    if (tdu && tduId) {
      set((state) => ({
        enroll: {
          ...state.enroll,
          enrollId,
          planId,
          retailerId,
          zipCode,
          sessionId,
          tdu,
          tduId,
        },
      }));
    } else if (tdu) {
      set((state) => ({
        enroll: {
          ...state.enroll,
          enrollId,
          planId,
          retailerId,
          zipCode,
          sessionId,
          tdu,
        },
      }));
    } else if (tduId) {
      set((state) => ({
        enroll: {
          ...state.enroll,
          enrollId,
          planId,
          retailerId,
          zipCode,
          sessionId,
          tduId,
        },
      }));
    } else {
      set((state) => ({
        enroll: {
          ...state.enroll,
          enrollId,
          planId,
          retailerId,
          zipCode,
          sessionId,
        },
      }));
    }
  },
  setEnroll_step: (enroll_step) =>
    set((state) => ({ enroll: { ...state.enroll, step: enroll_step } })),
  setEnroll_creditInfo: (creditInfo) =>
    set((state) => ({ enroll: { ...state.enroll, creditInfo } })),
  setEnroll_creditScore_provider_accountId: (creditScore_provider_accountId) =>
    set((state) => ({
      enroll: { ...state.enroll, creditScore_provider_accountId },
    })),
  setEnroll_creditScore_provider_id: (creditScore_provider_id) =>
    set((state) => ({ enroll: { ...state.enroll, creditScore_provider_id } })),
  setEnroll_creditScore_provider: (creditScore_provider) =>
    set((state) => ({ enroll: { ...state.enroll, creditScore_provider } })),
  setEnroll_creditScore: (creditScore) =>
    set((state) => ({ enroll: { ...state.enroll, creditScore } })),
  setEnroll_dob: (dob) =>
    set((state) => ({ enroll: { ...state.enroll, dob } })),
  setEnroll_email_verified_at: (email_verified_at) =>
    set((state) => ({ enroll: { ...state.enroll, email_verified_at } })),
  setEnroll_email_verified: (email_verified) => {
    set((state) => ({ enroll: { ...state.enroll, email_verified } }));
    if (email_verified) {
      set((state) => ({
        enroll: { ...state.enroll, email_verified_at: Date.now() },
      }));
    }
  },
  setEnroll_email: (email) =>
    set((state) => ({ enroll: { ...state.enroll, email } })),
  setEnroll_error: (error) =>
    set((state) => ({ enroll: { ...state.enroll, error } })),
  setEnroll_errorMessage: (errorMessage) =>
    set((state) => ({ enroll: { ...state.enroll, errorMessage } })),
  setEnroll_firstName: (firstName) =>
    set((state) => ({ enroll: { ...state.enroll, firstName } })),
  setEnroll_enrollId: (enrollId) =>
    set((state) => ({ enroll: { ...state.enroll, enrollId } })),
  setEnroll_lastName: (lastName) =>
    set((state) => ({ enroll: { ...state.enroll, lastName } })),
  setEnroll_paymentMethod_billing_address_city: (
    paymentMethod_billing_address_city
  ) =>
    set((state) => ({
      enroll: { ...state.enroll, paymentMethod_billing_address_city },
    })),
  setEnroll_paymentMethod_billing_address_country: (
    paymentMethod_billing_address_country
  ) =>
    set((state) => ({
      enroll: { ...state.enroll, paymentMethod_billing_address_country },
    })),
  setEnroll_paymentMethod_billing_address_email: (
    paymentMethod_billing_address_email
  ) =>
    set((state) => ({
      enroll: { ...state.enroll, paymentMethod_billing_address_email },
    })),
  setEnroll_paymentMethod_billing_address_line1: (
    paymentMethod_billing_address_line1
  ) => {
    set((state) => ({
      enroll: { ...state.enroll, paymentMethod_billing_address_line1 },
    }));
  },
  setEnroll_paymentMethod_billing_address_line2: (
    paymentMethod_billing_address_line2
  ) =>
    set((state) => ({
      enroll: { ...state.enroll, paymentMethod_billing_address_line2 },
    })),
  setEnroll_paymentMethod_billing_address_name: (
    paymentMethod_billing_address_name
  ) =>
    set((state) => ({
      enroll: { ...state.enroll, paymentMethod_billing_address_name },
    })),
  setEnroll_paymentMethod_billing_address_phone: (
    paymentMethod_billing_address_phone
  ) =>
    set((state) => ({
      enroll: { ...state.enroll, paymentMethod_billing_address_phone },
    })),
  setEnroll_paymentMethod_billing_address_sameAsEnrollAddress: (
    paymentMethod_billing_address_sameAsEnrollAddress
  ) =>
    set((state) => ({
      enroll: {
        ...state.enroll,
        paymentMethod_billing_address_sameAsEnrollAddress,
        paymentMethod_billing_address_city: state.enroll.service_address_city,
        paymentMethod_billing_address_line1: state.enroll.service_address_line1,
        paymentMethod_billing_address_line2: state.enroll.service_address_line2,
        paymentMethod_billing_address_state: state.enroll.service_address_state,
        paymentMethod_billing_address_zip: state.enroll.service_address_zip,
      },
    })),
  setEnroll_paymentMethod_billing_address_state: (
    paymentMethod_billing_address_state
  ) =>
    set((state) => ({
      enroll: { ...state.enroll, paymentMethod_billing_address_state },
    })),
  setEnroll_paymentMethod_billing_address_verified_at: (
    paymentMethod_billing_address_verified_at
  ) =>
    set((state) => ({
      enroll: { ...state.enroll, paymentMethod_billing_address_verified_at },
    })),
  setEnroll_paymentMethod_billing_address_verified: (
    paymentMethod_billing_address_verified
  ) => {
    set((state) => ({
      enroll: { ...state.enroll, paymentMethod_billing_address_verified },
    }));
    if (paymentMethod_billing_address_verified) {
      set((state) => ({
        enroll: {
          ...state.enroll,
          paymentMethod_billing_address_verified_at: Date.now(),
        },
      }));
    }
  },
  setEnroll_paymentMethod_billing_address_zip: (
    paymentMethod_billing_address_zip
  ) =>
    set((state) => ({
      enroll: { ...state.enroll, paymentMethod_billing_address_zip },
    })),
  setEnroll_paymentMethod_cardNumber: (paymentMethod_cardNumber) =>
    set((state) => ({ enroll: { ...state.enroll, paymentMethod_cardNumber } })),
  setEnroll_paymentMethod_cardName: (paymentMethod_cardName) =>
    set((state) => ({ enroll: { ...state.enroll, paymentMethod_cardName } })),
  setEnroll_paymentMethod_cardType: (paymentMethod_cardType) =>
    set((state) => ({ enroll: { ...state.enroll, paymentMethod_cardType } })),
  setEnroll_paymentMethod_expMonth: (paymentMethod_expMonth) =>
    set((state) => ({ enroll: { ...state.enroll, paymentMethod_expMonth } })),
  setEnroll_paymentMethod_expYear: (paymentMethod_expYear) =>
    set((state) => ({ enroll: { ...state.enroll, paymentMethod_expYear } })),
  setEnroll_paymentMethod_last4: (paymentMethod_last4) =>
    set((state) => ({ enroll: { ...state.enroll, paymentMethod_last4 } })),
  setEnroll_paymentMethod: (paymentMethod) =>
    set((state) => ({ enroll: { ...state.enroll, paymentMethod } })),
  setEnroll_paymentMethod_verified_at: (paymentMethod_verified_at) =>
    set((state) => ({
      enroll: { ...state.enroll, paymentMethod_verified_at },
    })),
  setEnroll_paymentMethod_verified: (paymentMethod_verified) => {
    set((state) => ({ enroll: { ...state.enroll, paymentMethod_verified } }));
    if (paymentMethod_verified) {
      set((state) => ({
        enroll: { ...state.enroll, paymentMethod_verified_at: Date.now() },
      }));
    }
  },
  setEnroll_phone_verified_at: (phone_verified_at) =>
    set((state) => ({ enroll: { ...state.enroll, phone_verified_at } })),
  setEnroll_phone_verified: (phone_verified) => {
    set((state) => ({ enroll: { ...state.enroll, phone_verified } }));
    if (phone_verified) {
      set((state) => ({
        enroll: { ...state.enroll, phone_verified_at: Date.now() },
      }));
    }
  },
  setEnroll_phone: (phone) =>
    set((state) => ({ enroll: { ...state.enroll, phone } })),
  setEnroll_service_address_place: (service_address_place) =>
    set((state) => ({ enroll: { ...state.enroll, service_address_place } })),
  setEnroll_service_address_esiId: (service_address_esiId) =>
    set((state) => ({ enroll: { ...state.enroll, service_address_esiId } })),
  setEnroll_service_address_city: (service_address_city) =>
    set((state) => ({ enroll: { ...state.enroll, service_address_city } })),
  setEnroll_service_address_line1: (service_address_line1) => {
    set((state) => ({ enroll: { ...state.enroll, service_address_line1 } }));
  },
  setEnroll_service_address_line2: (service_address_line2) =>
    set((state) => ({ enroll: { ...state.enroll, service_address_line2 } })),
  setEnroll_service_address_state: (service_address_state) =>
    set((state) => ({ enroll: { ...state.enroll, service_address_state } })),
  setEnroll_service_address_zip: (service_address_zip) =>
    set((state) => ({ enroll: { ...state.enroll, service_address_zip } })),
  setEnroll_service_address: (service_address) =>
    set((state) => ({ enroll: { ...state.enroll, service_address } })),
  setEnroll_service_coords: (service_coords) =>
    set((state) => ({ enroll: { ...state.enroll, service_coords } })),
  setEnroll_service_esiId: (service_esiId) =>
    set((state) => ({ enroll: { ...state.enroll, service_esiId } })),
  setEnroll_service_estimatedUsage: (service_estimatedUsage) =>
    set((state) => ({ enroll: { ...state.enroll, service_estimatedUsage } })),
  setEnroll_service_homeSize: (service_homeSize) =>
    set((state) => ({ enroll: { ...state.enroll, service_homeSize } })),
  setEnroll_service_meterNumber: (service_meterNumber) =>
    set((state) => ({ enroll: { ...state.enroll, service_meterNumber } })),
  setEnroll_service_place: (service_place) =>
    set((state) => ({ enroll: { ...state.enroll, service_place } })),
  setEnroll_service_startDate: (service_startDate) =>
    set((state) => ({ enroll: { ...state.enroll, service_startDate } })),
  setEnroll_service_startDate_earliestPossible: (
    service_startDate_earliestPossible
  ) =>
    set((state) => ({
      enroll: { ...state.enroll, service_startDate_earliestPossible },
    })),
  setEnroll_service_status: (service_status) =>
    set((state) => ({ enroll: { ...state.enroll, service_status } })),
  setEnroll_service_type: (service_type) =>
    set((state) => ({ enroll: { ...state.enroll, service_type } })),
  setEnroll_service_changeType: (service_changeType) =>
    set((state) => ({ enroll: { ...state.enroll, service_changeType } })),
  setEnroll_ssn: (ssn) =>
    set((state) => ({ enroll: { ...state.enroll, ssn } })),
  setEnroll_usageTier: (usageTier) =>
    set((state) => ({ enroll: { ...state.enroll, usageTier } })),
  setEnroll_userId: (userId) =>
    set((state) => ({ enroll: { ...state.enroll, userId } })),
  setEnroll_planId: (planId) =>
    set((state) => ({ enroll: { ...state.enroll, planId } })),
  setEnroll_retailerId: (retailerId) =>
    set((state) => ({ enroll: { ...state.enroll, retailerId } })),
  setEnroll_tduId: (tduId) =>
    set((state) => ({ enroll: { ...state.enroll, tduId } })),
  setEnroll_tdu: (tdu) =>
    set((state) => ({ enroll: { ...state.enroll, tdu } })),
  setEnroll_sessionId: (sessionId) =>
    set((state) => ({ enroll: { ...state.enroll, sessionId } })),
  setEnroll: (enroll) => set({ enroll }),
}));

export async function makeEnrollPack(sessionId: string, data: EnrollData) {
  const dataPack = { sessionId, enrollData: encrypt(JSON.stringify(data)) };
  return dataPack;
}
