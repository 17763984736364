/* eslint-disable react/no-unescaped-entities */
import SearchBar2 from "@/components/individual/newSearch";
import Container from "@/views/Container";
import Page from "@/views/Page";
const Hero = (): JSX.Element => {
  return (
    <div className="relative bg-[#E7E8EB] -mt-13 pt-13 w-full">
      <Container>
        <div className="flex flex-col md:flex-row gap-8 max-w-[1200px]">
          <div className="w-full md:w-[70%] flex flex-col items-center">
            <div className="lg:max-w-[500px] text-center lg:text-left">
              <div className="mb-4 cursor-default">
                <h1 className="text-4xl md:text-6xl lg:text-8xl text-[#377DFF] font-semibold mb-2">
                  Get Electricity
                </h1>
                <h2 className="text-3xl md:text-6xl font-semibold text-gray-900">
                  Power Your Home For Less
                </h2>
              </div>
              <p className="text-lg text-gray-600 mb-6">
                Compare rates, lock in your best energy plan, and get back to
                your day confident you made the right choice.
              </p>
            </div>
            <div className="w-full">
              <SearchBar2 bgColor="bg-white" />
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center items-center">
            <div className="w-full max-w-[300px] lg:max-w-[500px]">
              <img
                src="https://storage.googleapis.com/getelectricity-assets/home-img-animated.svg"
                alt="Hero Image"
                className="w-full h-full dark:brightness-75"
              />
            </div>
          </div>
        </div>
      </Container>
      <svg
        className="w-full -mb-1"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
      >
        <path
          fill="currentColor"
          className="text-white"
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </svg>
    </div>
  );
};

export default Hero;
