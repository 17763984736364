import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { useSessionStore } from "@/providers/store";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { config } from "@/config";
import { useEnrollStore } from "@/providers/enrollStore";
import { usePlansStore } from "@/providers/planStore";
import { useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Phone as PhoneIcon,
  LoaderPinwheel as LoaderPinwheelIcon,
} from "lucide-react";
import DatePicker from "./DatePicker";
import { EnrollStep, EnrollState } from "@/providers/enrollStore";
import { useEffect } from "react";
import { Switch } from "@/components/ui/switch";
import Map from "@/components/Map/Map";
import { Source } from "@/types/plan";
import Api from "@/utils/api";
const tabs = [
  {
    index: 0,
    value: "service-information",
    display: "Service Information",
  },
  {
    index: 1,
    value: "billing-information",
    display: "Billing Information",
  },
  {
    index: 2,
    value: "review-submit",
    display: "Review & Submit",
  },
];

const fields = [
  {
    id: "firstName",
    label: "First Name",
    type: "text",
    required: true,
  },
  {
    id: "lastName",
    label: "Last Name",
    type: "text",
    required: true,
  },
  {
    id: "email",
    label: "Email",
    type: "text",
    required: true,
  },
  {
    id: "phone",
    label: "Phone",
    type: "text",
    required: true,
  },
];

export const phoneNumber = (isMobile: boolean) => {
  const enroll = useEnrollStore();
  const [areaCode, setAreaCode] = useState(enroll.enroll.phone.slice(0, 3));
  const [phone, setPhone] = useState(enroll.enroll.phone.slice(3, 10));

  const handleAreaCodeChange = (e: string) => {
    setAreaCode(e);
    enroll.setEnroll_phone(areaCode + phone);
  };
  const handlePhoneChange = (e: string) => {
    setPhone(e);
    enroll.setEnroll_phone(areaCode + phone);
  };
  return (
    <div className="flex flex-col">
      <InputOTP
        maxLength={3}
        className={`flex ${isMobile ? "flex-col" : "flex-row"} w-full`}
        value={areaCode}
        onChange={handleAreaCodeChange}
      >
        <InputOTPGroup>
          <InputOTPSlot index={0} />
          <InputOTPSlot index={1} />
          <InputOTPSlot index={2} />
        </InputOTPGroup>
        <InputOTPSeparator />
      </InputOTP>
      <InputOTP
        maxLength={7}
        className={`flex ${isMobile ? "flex-col" : "flex-row"} w-full`}
        value={phone}
        onChange={handlePhoneChange}
      >
        <InputOTPGroup>
          <InputOTPSlot index={0} />
          <InputOTPSlot index={1} />
          <InputOTPSlot index={2} />
        </InputOTPGroup>
        <InputOTPSeparator />
        <InputOTPGroup>
          <InputOTPSlot index={3} />
          <InputOTPSlot index={4} />
          <InputOTPSlot index={5} />
          <InputOTPSlot index={6} />
        </InputOTPGroup>
      </InputOTP>
    </div>
  );
};

export function movingSwitching() {
  return (
    <div className="flex items-center space-x-2 flex-col border-2 border-gray-300 rounded-md p-2">
      <Label className="font-bold pb-2">
        Are you moving or switching services?
      </Label>
      <ToggleGroup
        type="single"
        defaultValue="moving"
        className="border-2 border-gray-300 rounded-md"
      >
        <ToggleGroupItem
          value="moving"
          aria-label="Toggle Moving To New Address"
        >
          Moving
        </ToggleGroupItem>
        <ToggleGroupItem
          value="switching"
          aria-label="Toggle Switching To New Service"
        >
          Switching
        </ToggleGroupItem>
      </ToggleGroup>
    </div>
  );
}

export function TabsStepper({
  activeTab,
  onTabChange,
  enroll,
}: {
  activeTab: string;
  onTabChange: (tab: string) => void;
  enroll: EnrollState;
}) {
  const isMobile = useMediaQuery("(max-width:600px)");

  if (isMobile) {
    const selectedTabIndex = tabs.findIndex((tab) => tab.value === activeTab);

    return (
      <TabsList className="flex w-full justify-between items-center">
        <button
          onClick={() =>
            onTabChange(tabs[Math.max(0, selectedTabIndex - 1)].value)
          }
          disabled={selectedTabIndex === 0}
          className="p-2"
          aria-label="Previous tab"
        >
          <ArrowLeftIcon />
        </button>
        <TabsTrigger value={activeTab}>
          {tabs[selectedTabIndex].display}
        </TabsTrigger>
        <button
          onClick={() =>
            onTabChange(
              tabs[Math.min(tabs.length - 1, selectedTabIndex + 1)].value
            )
          }
          disabled={selectedTabIndex === tabs.length - 1}
          className="p-2"
          aria-label="Next tab"
        >
          <ArrowRightIcon />
        </button>
      </TabsList>
    );
  }

  return (
    <TabsList className={`grid w-full grid-cols-${tabs.length}`}>
      {tabs.map((tab) => (
        <TabsTrigger
          key={tab.value}
          value={tab.value}
          disabled={
            activeTab === "review-submit" &&
            enroll.enroll.step === EnrollStep.CREDIT_CHECK
          }
        >
          {tab.display}
        </TabsTrigger>
      ))}
    </TabsList>
  );
}

export function EnrollmentStepper() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [date, setDate] = React.useState<Date | undefined>(new Date());
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const displayMonths = new Date().getDate() > 7 ? 2 : 1;
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState("");
  const { sessionId } = useSessionStore();
  const enroll = useEnrollStore();
  const { selectedPlan } = usePlansStore();
  console.log("Home Info Zip ", enroll.enroll.service_address_zip);
  console.log("Selected Plan ", selectedPlan);
  console.log("Enroll State ", enroll.enroll);
  useEffect(() => {
    if (activeTab === "service-information") {
      enroll.setEnroll_step(EnrollStep.SERVICE_DETAILS);
    }
    if (activeTab === "billing-information") {
      enroll.setEnroll_step(EnrollStep.PAYMENT_METHOD);
    }
    if (activeTab === "review-submit") {
      enroll.setEnroll_step(EnrollStep.REVIEW_ORDER);
    }
  }, [activeTab]);

  const handleSubmitServiceInfo = async () => {
    console.log("Submitting Service Info");
    const response = await Api.enrollmentStep(sessionId, enroll.enroll);
    console.log("Response ", response);
    if (response) {
      setActiveTab(tabs[1].value);
    }
  };

  return (
    <Tabs
      className="flex flex-col m-auto w-full h-full overflow-auto"
      value={activeTab}
      onValueChange={setActiveTab}
    >
      <TabsStepper
        activeTab={activeTab}
        onTabChange={setActiveTab}
        enroll={enroll}
      />
      <TabsContent value="service-information">
        <Card className="h-full overflow-auto">
          <CardHeader>
            <CardTitle>Service Information</CardTitle>
            <CardDescription>
              Information about the property you'll be enrolling.
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            {movingSwitching()}
            <div
              className={`space-y-1 ${
                isMobile ? "w-full" : ""
              } align-center margin-auto border-2 border-gray-300 rounded-md p-2`}
            >
              <Label htmlFor="service_startDate" className="font-bold">
                Select Service Start Date
              </Label>
              <div className="flex items-center justify-center space-x-2">
                <Switch
                  id="service_startDate_earliest"
                  checked={enroll.enroll.service_startDate_earliestPossible}
                  onCheckedChange={(checked) =>
                    enroll.setEnroll_service_startDate_earliestPossible(checked)
                  }
                />
                <Label htmlFor="service_startDate_earliest">
                  Earliest Possible Date
                </Label>
                <DatePicker
                  date={date}
                  setDate={setDate}
                  disabled={enroll.enroll.service_startDate_earliestPossible}
                />
                <p
                  className={`text-center ${
                    enroll.enroll.service_startDate_earliestPossible
                      ? "text-gray-400"
                      : ""
                  }`}
                >
                  Selected Date:{" "}
                  {enroll.enroll.service_startDate_earliestPossible
                    ? "Earliest Possible Date"
                    : date?.toLocaleDateString()}
                </p>
              </div>
            </div>
            <div className="border-2 border-gray-300 rounded-md p-2 space-y-1">
              <Label className="font-bold">Customer Information</Label>
              <div className="space-y-1">
                <Label htmlFor="customer_firstName">First Name</Label>
                <Input
                  id="customer_firstName"
                  value={enroll.enroll.firstName}
                  onChange={(e) => enroll.setEnroll_firstName(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <Label htmlFor="customer_lastName">Last Name</Label>
                <Input
                  id="customer_lastName"
                  value={enroll.enroll.lastName}
                  onChange={(e) => enroll.setEnroll_lastName(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <Label htmlFor="customer_email">Email</Label>
                <Input
                  id="customer_email"
                  value={enroll.enroll.email}
                  onChange={(e) => enroll.setEnroll_email(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <Label htmlFor="customer_phone">Phone</Label>
                <Input
                  id="customer_phone"
                  value={enroll.enroll.phone}
                  onChange={(e) => enroll.setEnroll_phone(e.target.value)}
                />
              </div>
            </div>

            <div className="border-2 border-gray-300 rounded-md p-2 space-y-1">
              <Label className="font-bold">Service Address</Label>
              <div className="space-y-1" id="service_address_line1">
                <Label htmlFor="service_address_line1">Address Line 1</Label>
                <Input
                  id="service_address_line1"
                  value={enroll.enroll.service_address_line1}
                  onChange={(e) =>
                    enroll.setEnroll_service_address_line1(e.target.value)
                  }
                />
              </div>
              <div className="space-y-1" id="service_address_line2">
                <Label htmlFor="service_address_line2">Address Line 2</Label>
                <Input
                  id="service_address_line2"
                  value={enroll.enroll.service_address_line2}
                  onChange={(e) =>
                    enroll.setEnroll_service_address_line2(e.target.value)
                  }
                />
              </div>
              <div className="grid grid-cols-3 gap-2" id="service_address_city">
                <div className="space-y-1">
                  <Label htmlFor="service_address_city">City</Label>
                  <Input
                    id="service_address_city"
                    value={enroll.enroll.service_address_city}
                    onChange={(e) =>
                      enroll.setEnroll_service_address_city(e.target.value)
                    }
                  />
                </div>
                <div className="space-y-1" id="service_address_state">
                  <Label htmlFor="service_address_state">State</Label>
                  <Input
                    id="service_address_state"
                    value={enroll.enroll.service_address_state}
                    onChange={(e) =>
                      enroll.setEnroll_service_address_state(e.target.value)
                    }
                  />
                </div>
                <div className="space-y-1" id="service_address_zip">
                  <Label htmlFor="service_address_zip">Zip Code</Label>
                  <Input
                    id="service_address_zip"
                    value={enroll.enroll.service_address_zip}
                    onChange={(e) =>
                      enroll.setEnroll_service_address_zip(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="space-y-1" id="ssn">
                <Label htmlFor="ssn">SSN</Label>
                <Input
                  id="ssn"
                  value={enroll.enroll.ssn}
                  onChange={(e) => enroll.setEnroll_ssn(e.target.value)}
                />
              </div>
              <div className="space-y-1" id="phone">
                <Label htmlFor="phone">Phone Number</Label>
                <Input
                  id="phone"
                  value={enroll.enroll.phone}
                  onChange={(e) => enroll.setEnroll_phone(e.target.value)}
                />
              </div>
              <div className="space-y-1" id="dob">
                <Label htmlFor="dob">Date of Birth</Label>
                <Input
                  id="dob"
                  value={enroll.enroll.dob}
                  onChange={(e) => enroll.setEnroll_dob(e.target.value)}
                />
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex justify-between">
            <Button variant="outline" className="hover:bg-red-500">
              Cancel
            </Button>
            <Button
              className="hover:bg-green-500"
              onClick={handleSubmitServiceInfo}
            >
              Continue
            </Button>
          </CardFooter>
        </Card>
      </TabsContent>
      <TabsContent value="billing-information">
        <Card>
          <CardHeader>
            <CardTitle>Billing Information</CardTitle>
            <CardDescription>
              Add billing information to your account.
            </CardDescription>
          </CardHeader>
          <CardContent className="grid gap-6">
            <RadioGroup defaultValue="card" className="grid grid-cols-3 gap-4">
              <div>
                <RadioGroupItem
                  value="card"
                  id="card"
                  className="peer sr-only"
                  aria-label="Card"
                />
                <Label
                  htmlFor="card"
                  className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-transparent p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="mb-3 h-6 w-6"
                  >
                    <rect width="20" height="14" x="2" y="5" rx="2" />
                    <path d="M2 10h20" />
                  </svg>
                  Card
                </Label>
              </div>
            </RadioGroup>

            <div className="border-2 border-gray-300 rounded-md p-2 space-y-1">
              <Label className="font-bold">Billing Address</Label>
              <div className="flex items-center space-x-2 m-2">
                <Switch
                  id="billing_address_same_as_service"
                  checked={
                    enroll.enroll
                      .paymentMethod_billing_address_sameAsEnrollAddress
                  }
                  onCheckedChange={(checked) =>
                    enroll.setEnroll_paymentMethod_billing_address_sameAsEnrollAddress(
                      checked
                    )
                  }
                />
                <Label htmlFor="billing_address_same_as_service">
                  Same as Service Address
                </Label>
              </div>

              <div className="space-y-1">
                <Label
                  htmlFor="billing_address_line1"
                  className={`${
                    enroll.enroll
                      .paymentMethod_billing_address_sameAsEnrollAddress
                      ? "text-gray-400"
                      : ""
                  }`}
                >
                  Address Line 1
                </Label>
                <Input
                  id="billing_address_line1"
                  value={
                    enroll.enroll
                      .paymentMethod_billing_address_sameAsEnrollAddress
                      ? enroll.enroll.service_address_line1
                      : enroll.enroll.paymentMethod_billing_address_line1
                  }
                  onChange={(e) =>
                    enroll.setEnroll_paymentMethod_billing_address_line1(
                      e.target.value
                    )
                  }
                  disabled={
                    enroll.enroll
                      .paymentMethod_billing_address_sameAsEnrollAddress
                  }
                />
              </div>
              <div className="space-y-1">
                <Label
                  htmlFor="billing_address_line2"
                  className={`${
                    enroll.enroll
                      .paymentMethod_billing_address_sameAsEnrollAddress
                      ? "text-gray-400"
                      : ""
                  }`}
                >
                  Address Line 2
                </Label>
                <Input
                  id="billing_address_line2"
                  value={
                    enroll.enroll
                      .paymentMethod_billing_address_sameAsEnrollAddress
                      ? enroll.enroll.service_address_line2
                      : enroll.enroll.paymentMethod_billing_address_line2
                  }
                  onChange={(e) =>
                    enroll.setEnroll_paymentMethod_billing_address_line2(
                      e.target.value
                    )
                  }
                  disabled={
                    enroll.enroll
                      .paymentMethod_billing_address_sameAsEnrollAddress
                  }
                />
              </div>
              <div className="grid grid-cols-3 gap-2">
                <div className="space-y-1">
                  <Label
                    htmlFor="billing_address_city"
                    className={`${
                      enroll.enroll
                        .paymentMethod_billing_address_sameAsEnrollAddress
                        ? "text-gray-400"
                        : ""
                    }`}
                  >
                    City
                  </Label>
                  <Input
                    id="billing_address_city"
                    value={
                      enroll.enroll
                        .paymentMethod_billing_address_sameAsEnrollAddress
                        ? enroll.enroll.service_address_city
                        : enroll.enroll.paymentMethod_billing_address_city
                    }
                    onChange={(e) =>
                      enroll.setEnroll_paymentMethod_billing_address_city(
                        e.target.value
                      )
                    }
                    disabled={
                      enroll.enroll
                        .paymentMethod_billing_address_sameAsEnrollAddress
                    }
                  />
                </div>
                <div className="space-y-1">
                  <Label
                    htmlFor="billing_address_state"
                    className={`${
                      enroll.enroll
                        .paymentMethod_billing_address_sameAsEnrollAddress
                        ? "text-gray-400"
                        : ""
                    }`}
                  >
                    State
                  </Label>
                  <Input
                    id="billing_address_state"
                    value={
                      enroll.enroll
                        .paymentMethod_billing_address_sameAsEnrollAddress
                        ? enroll.enroll.service_address_state
                        : enroll.enroll.paymentMethod_billing_address_state
                    }
                    onChange={(e) =>
                      enroll.setEnroll_paymentMethod_billing_address_state(
                        e.target.value
                      )
                    }
                    disabled={
                      enroll.enroll
                        .paymentMethod_billing_address_sameAsEnrollAddress
                    }
                  />
                </div>
                <div className="space-y-1">
                  <Label
                    htmlFor="billing_address_zip"
                    className={`${
                      enroll.enroll
                        .paymentMethod_billing_address_sameAsEnrollAddress
                        ? "text-gray-400"
                        : ""
                    }`}
                  >
                    Zip Code
                  </Label>
                  <Input
                    id="billing_address_zip"
                    value={
                      enroll.enroll
                        .paymentMethod_billing_address_sameAsEnrollAddress
                        ? enroll.enroll.service_address_zip
                        : enroll.enroll.paymentMethod_billing_address_zip
                    }
                    onChange={(e) =>
                      enroll.setEnroll_paymentMethod_billing_address_zip(
                        e.target.value
                      )
                    }
                    disabled={
                      enroll.enroll
                        .paymentMethod_billing_address_sameAsEnrollAddress
                    }
                  />
                </div>
              </div>
            </div>
            <div className="border-2 border-gray-300 rounded-md p-2 space-y-1">
              <Label className="font-bold">Card Information</Label>
              <div className="grid gap-2">
                <Label htmlFor="name">Name on Card</Label>
                <Input
                  id="name"
                  placeholder="First Last"
                  onChange={(e) =>
                    enroll.setEnroll_paymentMethod_cardName(e.target.value)
                  }
                  value={enroll.enroll.paymentMethod_cardName}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="number">Card number</Label>
                <Input
                  id="number"
                  placeholder=""
                  onChange={(e) =>
                    enroll.setEnroll_paymentMethod_cardNumber(e.target.value)
                  }
                  value={enroll.enroll.paymentMethod_cardNumber}
                />
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="grid gap-2">
                  <Label htmlFor="month">Expires</Label>
                  {/* <Select
                    value={
                      enroll.enroll.paymentMethod_expMonth.toString() || "1"
                    }
                    onValueChange={(value) =>
                      enroll.setEnroll_paymentMethod_expMonth(parseInt(value))
                    }
                  >
                    <SelectTrigger id="month" aria-label="Month">
                      <SelectValue placeholder="Month" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="1">January</SelectItem>
                      <SelectItem value="2">February</SelectItem>
                      <SelectItem value="3">March</SelectItem>
                      <SelectItem value="4">April</SelectItem>
                      <SelectItem value="5">May</SelectItem>
                      <SelectItem value="6">June</SelectItem>
                      <SelectItem value="7">July</SelectItem>
                      <SelectItem value="8">August</SelectItem>
                      <SelectItem value="9">September</SelectItem>
                      <SelectItem value="10">October</SelectItem>
                      <SelectItem value="11">November</SelectItem>
                      <SelectItem value="12">December</SelectItem>
                    </SelectContent>
                  </Select> */}
                  <Input
                    id="month"
                    placeholder="Month"
                    onChange={(e) =>
                      enroll.setEnroll_paymentMethod_expMonth(
                        parseInt(e.target.value)
                      )
                    }
                    value={
                      enroll.enroll.paymentMethod_expMonth
                        ? enroll.enroll.paymentMethod_expMonth.toString()
                        : ""
                    }
                  />
                </div>
                <div className="grid gap-2">
                  <Label htmlFor="year">Year</Label>
                  {/* <Select>
                    <SelectTrigger id="year" aria-label="Year">
                      <SelectValue placeholder="Year" />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from({ length: 10 }, (_, i) => (
                        <SelectItem
                          key={i}
                          value={`${new Date().getFullYear() + i}`}
                        >
                          {new Date().getFullYear() + i}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select> */}
                  <Input
                    id="year"
                    placeholder="Year"
                    onChange={(e) =>
                      enroll.setEnroll_paymentMethod_expYear(
                        parseInt(e.target.value)
                      )
                    }
                    value={
                      enroll.enroll.paymentMethod_expYear
                        ? enroll.enroll.paymentMethod_expYear.toString()
                        : ""
                    }
                  />
                </div>
                <div className="grid gap-2">
                  <Label htmlFor="cvc">CVC</Label>
                  <Input id="cvc" placeholder="CVC" />
                </div>
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex justify-between">
            <Button
              variant="outline"
              className="hover:bg-red-500"
              onClick={() => setActiveTab(tabs[0].value)}
            >
              Back
            </Button>
            <Button
              className="hover:bg-green-500"
              onClick={() => setActiveTab(tabs[2].value)}
            >
              Continue
            </Button>
          </CardFooter>
        </Card>
      </TabsContent>
      <TabsContent value="review-submit">
        <Card>
          <CardHeader>
            <CardTitle>Review & Submit</CardTitle>
            <CardDescription>
              Review your information and submit.
            </CardDescription>
          </CardHeader>

          <CardContent>
            {config.VERIFY_PHONE && (
              <div className="space-y-2 flex flex-col items-center">
                <Label>Verify your Phone Number</Label>
                <Button
                  className="hover:bg-green-500"
                  onClick={() => setCodeSent(true)}
                >
                  {codeSent ? "Resend Code" : "Send Code"}
                  &nbsp;
                  <PhoneIcon />
                </Button>
              </div>
            )}
            {codeSent && (
              <div className="space-y-2 mt-2 flex flex-col items-center">
                <Label>Enter Code</Label>
                <InputOTP
                  maxLength={3}
                  className={`flex ${
                    isMobile ? "flex-col" : "flex-row"
                  } w-full`}
                  value={code}
                  onChange={(e) => setCode(e)}
                >
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                  </InputOTPGroup>
                  <InputOTPSeparator />
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                  </InputOTPGroup>
                </InputOTP>
              </div>
            )}
            <Separator className="my-2" />
            <div className="space-y-2 mt-2 flex flex-col items-center">
              <Label>Review your information</Label>
              <div className="w-full space-y-4 md:space-y-0 md:flex md:gap-4">
                <div className="w-full md:w-1/2 space-y-4">
                  <div className="space-y-2 flex flex-col w-full border-2 border-gray-300 rounded-md p-2">
                    <Label>Plan Selected</Label>
                    <div className="flex flex-col pl-4">
                      <p className="font-bold text-xl">
                        {selectedPlan?.info?.["plan-name"]}
                      </p>
                      <p className="text-sm">{selectedPlan?.retailer?.name}</p>
                      <p className="text-sm">
                        Term: {selectedPlan?.["term-length"]} Months
                      </p>
                    </div>
                  </div>
                  <div className="space-y-2 flex flex-col w-full border-2 border-gray-300 rounded-md p-2">
                    <Label>Service Address</Label>
                    <p>{enroll.enroll.service_address_line1}</p>
                    <p>{enroll.enroll.service_address_line2}</p>
                    <p>
                      {enroll.enroll.service_address_city}{" "}
                      {enroll.enroll.service_address_state}{" "}
                      {enroll.enroll.service_address_zip}
                    </p>
                    <Label>Billing Address</Label>
                    <p>{enroll.enroll.paymentMethod_billing_address_line1}</p>
                    <p>{enroll.enroll.paymentMethod_billing_address_line2}</p>
                    <p>
                      {enroll.enroll.paymentMethod_billing_address_city}{" "}
                      {enroll.enroll.paymentMethod_billing_address_state}{" "}
                      {enroll.enroll.paymentMethod_billing_address_zip}
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  <Map />
                </div>
              </div>

              {selectedPlan?.info["source"] === "VBB" && (
                <>
                  {(enroll.enroll.step === EnrollStep.CREDIT_CHECK ||
                    enroll.enroll.step === EnrollStep.CREDIT_APPROVED ||
                    enroll.enroll.step === EnrollStep.CREDIT_RETURNED) && (
                    <div className="space-y-2 flex flex-col w-full border-2 border-gray-300 rounded-md p-2">
                      {enroll.enroll.step === EnrollStep.CREDIT_CHECK && (
                        <>
                          <Label>Verification</Label>
                          <p className="text-sm text-red-400">
                            We are verifying your information with the Electric
                            Provider.
                          </p>
                          <p className="text-sm text-red-400">
                            This process can take up to 2 minutes.
                          </p>
                          <p className="text-sm text-red-400">
                            Please do not close or refresh this page.
                          </p>
                          <LoaderPinwheelIcon
                            className="animate-spin"
                            width={32}
                            height={32}
                          />
                        </>
                      )}
                      {enroll.enroll.step === EnrollStep.CREDIT_RETURNED && (
                        <>
                          <Label>Verification</Label>
                          <p className="text-sm text-red-400">
                            We are unable to verify your information with the
                            Electric Provider.
                          </p>
                          <p className="text-sm text-red-400">
                            You're contact information has automatically been
                            sent to one of our agents for review.
                          </p>
                          <p className="text-sm text-red-400">
                            You will receive a call from one of our agents
                            within 24 hours.
                          </p>
                        </>
                      )}
                      {enroll.enroll.step === EnrollStep.CREDIT_APPROVED && (
                        <>
                          <Label>Verification</Label>
                          <p className="text-sm text-green-400">
                            Your information has been verified.
                          </p>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
              {(selectedPlan?.info["enroll-method"] === "LINKOFF" ||
                selectedPlan?.info["source"] === Source.PowerToChoose) && (
                <div className="space-y-2 flex flex-col w-full border-2 border-gray-300 rounded-md p-2">
                  <Label>Linkoff</Label>
                  <p className="text-sm text-red-400">
                    You will receive a link to your email to complete the
                    enrollment process.
                  </p>
                </div>
              )}
            </div>
          </CardContent>
          <CardFooter className="flex justify-between">
            <Button
              variant="outline"
              className="hover:bg-red-500"
              onClick={() => setActiveTab(tabs[1].value)}
              disabled={enroll.enroll.step === EnrollStep.CREDIT_CHECK}
            >
              Back
            </Button>
            {selectedPlan?.info["source"] === "VBB" && (
              <>
                {/* {enroll.enroll.step === EnrollStep.REVIEW_ORDER && (
                  <Button
                    className="hover:bg-green-500"
                    onClick={() =>
                      enroll.setEnroll_step(EnrollStep.CREDIT_CHECK)
                    }
                  >
                    Submit
                  </Button>
                )} */}
                {enroll.enroll.step !== EnrollStep.CREDIT_APPROVED && (
                  <Button
                    className="hover:bg-green-500"
                    onClick={() =>
                      enroll.setEnroll_step(EnrollStep.CREDIT_CHECK)
                    }
                    disabled={enroll.enroll.step === EnrollStep.CREDIT_CHECK}
                  >
                    Submit
                  </Button>
                )}
                {enroll.enroll.step === EnrollStep.CREDIT_APPROVED && (
                  <Button
                    className="hover:bg-green-500"
                    onClick={() =>
                      enroll.setEnroll_step(EnrollStep.CONFIRMATION)
                    }
                  >
                    Proceed
                  </Button>
                )}
              </>
            )}
          </CardFooter>
        </Card>
      </TabsContent>
    </Tabs>
  );
}

export default EnrollmentStepper;
