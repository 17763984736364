import { EnrollStep, useEnrollStore } from "@/providers/enrollStore";
import { useSessionStore } from "@/providers/store";
import SearchBar2 from "@/components/individual/newSearch";
import { useState } from "react";
import { Search } from "lucide-react";

export const PlanMapLocation = () => {
  const { enroll } = useEnrollStore();
  const { locationSpec } = useSessionStore();
  const [searchOpen, setSearchOpen] = useState(false);

  switch (locationSpec) {
    case "state":

    case "none":
      return (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <SearchBar2 />
        </div>
      );
    case "zip":
      return (
        <div className="w-full h-full flex flex-row items-center justify-center gap-2">
          <span className="text-xl font-semibold">
            {enroll.service_address_zip}
          </span>
          <div className="w-full h-full flex flex-row items-center justify-center gap-2">
            <SearchBar2
              enroll={false}
              placeholder="Enter your address to confirm availability and improve usage forecast"
            />
          </div>
        </div>
      );
    case "address":
      return (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <span className="text-xl font-semibold">
            {enroll.service_address}
          </span>
        </div>
      );
    default:
      return null;
  }
};
