import { cn } from "@/lib/utils";
import { usePlansStore } from "@/providers/planStore";
import { useEnrollStore } from "@/providers/enrollStore";
import { useSessionStore } from "@/providers/store";
import Api from "@/utils/api";
import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddressAutocomplete from "@/components/address/AddressAutocomplete";
import { useToast } from "@/hooks/use-toast";

const SearchBar2: React.FC<{
  bgColor?: string;
  enroll?: boolean;
  placeholder?: string;
}> = ({ bgColor, enroll = false, placeholder }) => {
  const { toast } = useToast();
  const [invalidValue, setInvalidValue] = React.useState(false);
  const [validated, setValidated] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const enrollStore = useEnrollStore();
  const sessionStore = useSessionStore();
  const { fetchPlans } = usePlansStore();
  const theme = useTheme();
  bgColor = bgColor || "bg-gray-100";
  const [navigateURL, setNavigateURL] = useState("");

  const shimmerButtonClass = `
  relative overflow-hidden
  max-w-[25%] md:max-w-[30%] lg:max-w-[33%] w-full h-10 m-1
  bg-mainBlue text-white rounded-full
  border-2 border-mainBlue
  text-xs md:text-base lg:text-lg
  transition-all duration-300
  hover:border-yellow-500
  hover:ring-2 hover:ring-yellow-500
  before:absolute before:inset-0
  before:bg-gradient-to-r 
  before:from-transparent before:via-white/50 before:to-transparent
  before:translate-x-[-100%]
  before:animate-shimmer
  before:hover:opacity-100
`;

  const baseButtonClass = `
  relative overflow-hidden
  h-10 w-10 m-1
  flex items-center justify-center 
  bg-inherit text-black rounded-full
  transition-all duration-300
`;

  const handleSearch = () => {
    sessionStore.updateSearches([searchValue]);
    setIsLoading(true);
    Api.checkZip(searchValue)
      .then((res) => {
        if (res) {
          enrollStore.setEnroll_service_address_zip(res.zipCode);
          enrollStore.setEnroll_service_address_state(res.state);
          enrollStore.setEnroll_service_address_city(res.city);
          setSearchValue(res.zipCode);
          navigate(`/${res.state.toUpperCase()}/${searchValue}/plans`);
          setInvalidValue(false);
        } else {
          setInvalidValue(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!searchValue) {
      setValidated(false);
    }
  }, [searchValue]);

  const handleAddressSelect = (address: string) => {
    setSearchValue(address);
    setValidated(true);
    // You can add additional logic here if needed
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div
        className={cn(
          "flex flex-row w-full rounded-full border-2 border-mainBlue",
          "text-base",
          bgColor
        )}
      >
        <AddressAutocomplete
          onSelectAddress={handleAddressSelect}
          placeholder={placeholder}
        />
        <button
          onClick={handleSearch}
          className={
            validated && searchValue ? shimmerButtonClass : baseButtonClass
          }
          hidden={!validated || !searchValue}
        >
          {validated && searchValue ? "Find Plans →" : "→"}
        </button>
      </div>
      {invalidValue && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 w-full px-4 py-2 rounded-r-lg">
          <p className="font-semibold">Invalid zip code</p>
          <p className="mt-2">Please enter a valid US zip code</p>
        </div>
      )}
    </div>
  );
};

export default SearchBar2;
