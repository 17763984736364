import "@/utils/leaflet-icons";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import { useSessionStore } from "@/providers/store";
import { useEffect, useState, useMemo } from "react";
import { MapPinHouse, Zap } from "lucide-react";
import { PlanMapLocation } from "./PlanMapLocation";
import KML from "react-leaflet-kml";
import Api from "@/utils/api";
import { useEnrollStore } from "@/providers/enrollStore";
import { Pane } from "../Pane";
import { pgPlanInfo } from "@/types/plan";
import { usePlansStore } from "@/providers/planStore";
const defaultPosition: LatLngExpression = { lat: 32.7294, lng: -96.9964 }; // Grand Prairie center

interface FilteredKMLProps {
  kmlData: Document;
  allowedZips: string[];
  showOutlines: boolean;
  color: string;
}

// Add this helper function at the top of the file
const hexToKMLColor = (hex: string, alpha: number = 0.3): string => {
  // Remove the # if present
  hex = hex.replace("#", "");

  // Convert hex to RGB
  const r = hex.substring(0, 2);
  const g = hex.substring(2, 4);
  const b = hex.substring(4, 6);

  // Convert alpha from 0-1 to hex
  const a = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, "0");

  // Return in KML format (aabbggrr)
  const kmlColor = `${a}${b}${g}${r}`;

  return kmlColor;
};

// Component to filter KML data
const FilteredKML = ({
  kmlData,
  allowedZips,
  showOutlines,
  color,
}: FilteredKMLProps) => {
  const [filteredKml, setFilteredKml] = useState<Document | null>(null);
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (!kmlData) return;

    const newDoc = (kmlData as XMLDocument).implementation.createDocument(
      "http://www.opengis.net/kml/2.2",
      "kml",
      null
    );

    const documentEl = newDoc.createElement("Document");
    newDoc.documentElement.appendChild(documentEl);

    // Add default style (black outline or no outline based on showOutlines)
    const defaultStyle = newDoc.createElement("Style");
    defaultStyle.setAttribute("id", "defaultStyle");
    defaultStyle.innerHTML = `
      <LineStyle>
        <color>ff000000</color>
        <width>${showOutlines ? "1" : "0"}</width>
      </LineStyle>
      <PolyStyle>
        <color>00ffffff</color>
        <fill>1</fill>
        <outline>${showOutlines ? "1" : "0"}</outline>
      </PolyStyle>
      <LabelStyle>
        <color>ff000000</color>
        <scale>0.8</scale>
      </LabelStyle>
    `;
    documentEl.appendChild(defaultStyle);

    // Add highlighted style (red fill, always showing outline)
    const highlightStyle = newDoc.createElement("Style");
    highlightStyle.setAttribute("id", "highlightStyle");
    highlightStyle.innerHTML = `
      <LineStyle>
        <color>${color}</color>
        <width>2</width>
      </LineStyle>
      <PolyStyle>
        <color>${color}</color>
        <fill>1</fill>
        <outline>1</outline>
      </PolyStyle>
      <LabelStyle>
        <color>ff000000</color>
        <scale>0.8</scale>
      </LabelStyle>
    `;
    documentEl.appendChild(highlightStyle);

    // Copy name and description
    const originalName = kmlData.querySelector("name");
    const originalDesc = kmlData.querySelector("description");
    if (originalName) documentEl.appendChild(originalName.cloneNode(true));
    if (originalDesc) documentEl.appendChild(originalDesc.cloneNode(true));

    // Group placemarks by style (highlighted vs non-highlighted)
    const highlightedPlacemarks: Element[] = [];
    const defaultPlacemarks: Element[] = [];

    // First pass: Sort placemarks into groups
    const placemarks = kmlData.getElementsByTagName("Placemark");
    Array.from(placemarks).forEach((placemark) => {
      const extendedData = placemark.querySelector("ExtendedData");
      const zipDataElement = extendedData?.querySelector(
        'Data[name="ZCTA5CE10"]'
      );
      const zipValue = zipDataElement?.querySelector("value")?.textContent;

      if (zipValue) {
        const newPlacemark = placemark.cloneNode(true) as Element;
        // Add ZIP code as name
        const nameEl = newDoc.createElement("name");
        nameEl.textContent = zipValue;
        newPlacemark.insertBefore(nameEl, newPlacemark.firstChild);

        if (allowedZips.includes(zipValue)) {
          highlightedPlacemarks.push(newPlacemark);
        }
      }
    });

    // Create MultiGeometry for each group
    if (defaultPlacemarks.length > 0) {
      const defaultMultiGeometry = newDoc.createElement("Placemark");
      const multiGeom = newDoc.createElement("MultiGeometry");
      defaultMultiGeometry.appendChild(multiGeom);

      defaultPlacemarks.forEach((placemark) => {
        const polygon = placemark.querySelector("Polygon");
        if (polygon) {
          multiGeom.appendChild(polygon.cloneNode(true));
        }
      });

      const styleUrl = newDoc.createElement("styleUrl");
      styleUrl.textContent = "#defaultStyle";
      defaultMultiGeometry.appendChild(styleUrl);
      documentEl.appendChild(defaultMultiGeometry);
    }

    // Add highlighted placemarks individually to preserve labels
    highlightedPlacemarks.forEach((placemark) => {
      const styleUrl = newDoc.createElement("styleUrl");
      styleUrl.textContent = "#highlightStyle";
      placemark.appendChild(styleUrl);
      documentEl.appendChild(placemark);
    });

    setFilteredKml(newDoc);
    setKey((prev) => prev + 1);
  }, [kmlData, allowedZips, showOutlines]);

  return filteredKml ? <KML key={key} kml={filteredKml} /> : null;
};

// Component to handle map view updates
const MapUpdater = ({
  center,
  zoom = 12,
}: {
  center: LatLngExpression;
  zoom?: number;
}) => {
  const map = useMap();

  useEffect(() => {
    // Set initial view
    map.setView(center, zoom);

    // Force a resize check to ensure the map fits its container
    setTimeout(() => {
      map.invalidateSize();
    }, 100);
  }, [map, center, zoom]);

  return null;
};

export const PlanMap = () => {
  const { enroll } = useEnrollStore();
  const [kmlData, setKmlData] = useState<Document | null>(null);
  const planStore = usePlansStore();
  const [showOutlines, setShowOutlines] = useState(false);
  const [zipCodes, setZipCodes] = useState<string[]>([
    enroll.service_address_zip,
  ]);
  const [position, setPosition] = useState<LatLngExpression>(
    enroll.service_coords || defaultPosition
  );
  const [tdus, setTdus] = useState<any[]>([]);
  const [allTdus, setAllTdus] = useState<any[]>([]);

  // Load KML file only once
  useEffect(() => {
    fetch("/zips-tx.kml")
      .then((res) => res.text())
      .then((kmlText) => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlText, "text/xml");
        setKmlData(kml);
      })
      .catch((err) => console.error("Error loading KML:", err));
  }, []);

  useEffect(() => {
    setPosition(enroll.service_coords || defaultPosition);
  }, [enroll.service_coords]);

  useEffect(() => {
    if (planStore.plans.length > 0) {
      const tduSet = new Set(
        planStore.plans.map((plan: pgPlanInfo) => plan.tdu)
      );
      setTdus(Array.from(tduSet));
      Api.getTDUs().then((tdus) => {
        setAllTdus(tdus);
      });
    }
  }, [planStore.plans]);

  // Memoize getTduNames since it involves array operations
  const getTduNames = useMemo(() => {
    return (tduCodes: string[]): string[] => 
      tduCodes.map((code) => {
        const tdu = allTdus.find((tdu) => tdu.code === code);
        return tdu?.name || code;
      });
  }, [allTdus]);

  // Memoize the TDU chips since they only need to update when tdus or getTduNames changes
  const tduChips = useMemo(() => {
    return (icon: React.ReactNode) => 
      getTduNames(tdus).map((tdu) => (
        <div
          key={tdu}
          className="bg-gray-100 border border-gray-500 text-gray-800 rounded-full py-1 px-2 flex flex-row items-center justify-center gap-1 text-sm"
        >
          {icon}
          {tdu}
        </div>
      ));
  }, [tdus, getTduNames]);

  // Memoize the map style object since it's static
  const mapContainerStyle = useMemo(() => ({
    height: "20vh",
    width: "100%",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  }), []);

  // Memoize the color conversion since it's static
  const kmlColor = useMemo(() => 
    hexToKMLColor("#FF0000", 0.5)
  , []);

  return (
    <Pane>
      <MapContainer
        center={position}
        zoom={10}
        scrollWheelZoom={true}
        style={mapContainerStyle}
        className="z-0 rounded-t-lg"
      >
        <MapUpdater center={position} zoom={10} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <MapPinHouse
            className="w-12 h-12 text-mainBlue"
            style={{ strokeWidth: 1.5 }}
          />
        </Marker>
        {kmlData && (
          <FilteredKML
            kmlData={kmlData}
            allowedZips={zipCodes}
            showOutlines={showOutlines}
            color={kmlColor}
          />
        )}
      </MapContainer>
      <div className="w-full rounded-b-lg p-4 flex flex-col items-center justify-center">
        <div className="flex flex-row items-center justify-between gap-2 w-full">
          <div
            id="left"
            className="flex flex-row flex-1 items-center justify-center gap-4"
          >
            <MapPinHouse
              className="w-10 h-10 text-mainBlue"
              style={{ strokeWidth: 1.5 }}
            />
            <PlanMapLocation />
          </div>
          <div
            id="right"
            className="flex flex-row items-center justify-center gap-2"
          >
            {tdus.length > 0 && (
              <>
                <div className=" flex flex-row items-center justify-center gap-2">
                  {tduChips(<Zap className="w-4 h-4" style={{ strokeWidth: 1.5 }} />)}
                </div>
                <div className="bg-green-100 border border-green-500 text-green-800 rounded-full py-1 px-2 flex flex-row items-center justify-center gap-2 text-sm">
                  Service Available
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Pane>
  );
};
