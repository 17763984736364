import { CircleCheck } from "lucide-react";
import { PlanBadges } from "./components/PlanBadges";
import { PlanInfo } from "@/types/plan";
export const PlanCardContent = ({ plan }: { plan: PlanInfo }) => {
  return (
    <div className="grid grid-rows-2 gap-4 h-full">
      <PlanBadges plan={plan} />
      {plan["ai-analysis"]?.sellingPoints?.length > 0 && (
        <div className="flex flex-col items-left justify-start gap-1 text-sm">
          {plan["ai-analysis"]?.sellingPoints?.slice(0, 4).map((point:string, index:number) => (
            <div key={index} className="inline-flex items-center gap-4">
              <CircleCheck className="w-4 h-4 text-green-700 flex-shrink-0 self-start" />
              <span className="text-xs text-gray-500 leading-tight">{point}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
