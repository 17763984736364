import { PlanInfo, PlanPricing } from "@/types/plan";
import { usePlansStore } from "@/providers/planStore";

export const PriceDisplay: React.FC<{ plan: PlanInfo }> = ({ plan }) => {
  const planStore = usePlansStore();
  const filters = planStore.filters;
  return (
    <div className="flex flex-col items-center justify-center mt-4 mb-2 p-2 w-full rounded-xl">
      <div className="flex flex-row items-baseline justify-center gap-1 w-full">
        <span className="text-4xl font-bold text-mainBlue">
          {(
            plan["plan-pricing"][
              `kwh-${filters.usageTier.value}` as keyof PlanPricing
            ] as number
          )?.toFixed(1)}
          ¢
        </span>
        <span className="text-gray-600">/kWh</span>
      </div>
      <p className="text-xs text-gray-500">effective rate at 1000 kWh</p>
    </div>
  );
};
