import { Card, CardContent } from "@/components/ui/card";

import FilterFeatures from "./components/FilterFeatures/FilterFeatures";
import FilterLength from "./components/FilterLength/FilterLength";
import FilterPrice from "./components/FilterPrice/FilterPrice";
import FilterRetailers from "./components/FilterRetailers/FilterRetailers";

const FilterSidebar: React.FC = () => {
  return (
    <Card className="hidden sm:block">
      <CardContent className="flex flex-col h-full mx-4 my-4 space-y-6 max-w-[15vw]">
        <FilterPrice />
        <FilterLength />
        <FilterRetailers />
        <FilterFeatures />
      </CardContent>
    </Card>
  );
};

export default FilterSidebar;
