import { MapPinHouse, Target, Router, UtilityPole, MapPin } from "lucide-react";
import { useSessionStore } from "@/providers/store";
import { Button } from "@/components/ui/button";
import NewSearch from "@/components/individual/newSearch";
import { useEnrollStore } from "@/providers/enrollStore";
const sellPoints = [
  {
    icon: MapPinHouse,
    title: "Actual Usage Data",
    description:
      "See your home's real electricity usage data to find the right plan.",
  },
  {
    icon: Target,
    title: "Precise Matching",
    description:
      "Find the perfect plan for your home by matching your usage data.",
  },
  {
    icon: Router,
    title: "Monitor Usage",
    description:
      "We'll monitor your usage and alert you if we can switch you to a cheaper plan.",
  },
  {
    icon: UtilityPole,
    title: "Grid Info",
    description:
      "We monitor the grid and alert you if there are any outages in your area.",
  },
];

const IsThisRightForYou = () => {
  const { enroll } = useEnrollStore();
  return (
    <div className="w-full flex flex-col items-left justify-center my-4 border border-gray-200 p-4 rounded-lg">
      <h1 className="text-lg font-bold text-left text-mainBlue">
        Is This The Right Plan For Your Home?
      </h1>
      <p className="text-sm text-gray-500">
        Find out instantly by connecting your home with our smart meter link.
      </p>
      <div className="w-full flex flex-col items-center justify-center gap-2 mt-2">
        {sellPoints.map((sellPoint) => (
          <div
            key={sellPoint.title}
            className="flex flex-row w-full items-center justify-start gap-2 py-1"
          >
            <sellPoint.icon className="w-5 h-5" />
            <div className="flex flex-col items-start justify-start">
              <h2 className="text-sm font-semibold">{sellPoint.title}</h2>
              <p className="text-xs text-gray-500">{sellPoint.description}</p>
            </div>
          </div>
        ))}
      </div>
      {enroll.service_address_line1 ? (
        <div className="flex flex-row items-center justify-start gap-4 mt-2 p-4 border border-mainBlue rounded-lg">
          <Button size="sm">Connect</Button>
          <MapPin className="w-5 h-5" />
          <div className="flex flex-col items-start justify-start">
            <p className="text-sm text-gray-500">{enroll.service_address}</p>
          </div>
        </div>
      ) : (
        <div className="flex flex-row items-center justify-start gap-4 mt-2 p-4 border border-gray-200 rounded-lg">
          <NewSearch />
        </div>
      )}
    </div>
  );
};

export default IsThisRightForYou;
